.nav-menu {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ui.menu.profile-menu a.item:hover {
	background-color: #e9f0f7 !important;
}

.service-menu {
	display: inline-block !important;
}
.service-menu-container {
	width: 95px;
	border-radius: 8px !important;
	display: inline-block !important;
	border: 1px solid white;
}

.service-menu-container:hover,
.service-menu-container.active {
	background-color: #f7fbff !important;
	border: 1px solid #dbe0e5;
	font-weight: 700;
}

.service-menu-item {
	width: 50px;
	height: 50px;
	display: inline-block;
	padding: 16px 10px;
	margin: 10px;
	border-radius: 100px !important;
	background-color: #eff4ff;
	text-align: center;
	justify-content: center;
	/* border: 1px solid white; */
}
.menu.icon {
	width: 20px !important;
}
.service-menu-title {
	word-break: break-word;
	text-align: center;
	color: black;
}

.profile-menu .item {
	padding: 10px 10px !important;
}

.gcheck-toggle-label {
	margin-bottom: 5px;
	font-weight: bold;
	text-align: center;
}

.gcheck-toggle-checkbox-container {
	margin-top: 5px;
	margin: auto;
}

.gcheck-toggle-checkbox {
	display: block !important;
	margin: auto;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
	background-color: #00e600 !important;
}

.healthcheck-modal {
	width: 100%;
	max-width: 600px;
}
.healthcheck-modal .content {
	text-align: center;
	padding: 4rem !important;
}
.healthcheck-modal .content img {
	width: 120px;
}
.healthcheck-modal .content .modal-action {
	margin-top: 2rem;
}
.healthcheck-modal .content .modal-action .button:last-child {
	margin-bottom: 0;
}

.gcheck-toggle-btn {
	margin-top: 25px !important;
}
