.company-dashboard {
  padding-top: 190px;
}
.navigation {
  height: 80px;
  background-color: #0064d8;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 10;
}
.navigation-content {
  height: 100%;
  display: flex !important;
  align-items: center;
}
.navigation-item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: #fff;
}
.navigation-item:hover {
  color: #fff;
  background-color: #0053b4;
}
.navigation-item--active {
  background-color: #0053b4;
  position: relative;
}
@media screen and (min-width: 768px) {
  .navigation-item--active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(-45deg);
    height: 25px;
    width: 25px;
    background-color: #0053b4;
  }
}
