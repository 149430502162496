.advance-booking-fields {
	padding-left: 26px;
}
.booking-upload-field {
	background-color: #fcfcfc;
	padding: 1rem;
	border: 1px solid #707070;
	border-radius: 10px;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	width: 80% !important;
}
.booking-upload-field ul {
	padding-left: 1rem;
	margin-bottom: 0.5rem;
}
.booking-upload-field li {
	list-style-type: disc;
}
.subfields-group {
	padding-left: 33px;
}
.day-checkbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 85%;
}
.day-checkbox-item label {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #707070;
	padding: 5px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	/* color: #222; */
	cursor: pointer;
}
.day-checkbox-item label::before,
.day-checkbox-item label::after {
	display: none;
}
.day-checkbox-item input {
	display: none !important;
}
.day-checkbox-item.checked label {
	background-color: #559ff5 !important;
	color: #fff !important;
	border-color: #559ff5 !important;
}

.form-group {
	margin: 0 0 40px 0 !important;
}
.schedule-container {
	padding: 0.5rem 0 !important;
}
.similar-across-timer {
	/* padding-left: 1.25em !important; */
	padding-left: 11px !important;
	margin-top: -1em !important;
}
.booking-grid {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.ui.labeled.input > .label:not(.corner) {
	border-left: 0.5px solid #707070 !important;
}

/* Hide arrows in input type=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield !important;
}

#custom-limited-input {
	background-color: #dadada;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border: solid 0.25px #707070 !important;
	min-width: 130px !important;
	font-weight: 500 !important;
}

.custom-limited-icon {
	position: absolute;
	right: 10px;
}

.w70 {
	width: 110% !important;
	/* min-width: 50% !important; */
}
.sub-text {
	color: #6f8191;
}
.custom-label-white-space {
	white-space: nowrap;
}

.white-space-pre {
	white-space: pre;
}
