.shared-slot-name {
	font-size: smaller;
	color: #6f8191;
	font-weight: 500;
}

.margin-left-shared-label {
	margin-left: 1.5rem;
}

.active-shared-status {
	background-color: #19cb5d;
	border: 1px solid #19cb5d;
}
.font-weight-normal {
	font-weight: normal !important;
}
