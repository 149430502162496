body {
	background-color: #f4f8fc;
}

.top-header {
	background-color: #fff;
	box-shadow: 0px 3px 16px #00000010;
	padding: 0 1rem;
	height: 70px;
	z-index: 2000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

@media screen and (max-width: 767px) {
	.top-header {
		padding: 0;
	}
}

.top-header .ui.grid {
	margin-top: 0;
}

.top-header-content {
	padding-top: 0 !important;
	display: flex;
	align-items: center;
}

.top-header-content-right {
	justify-content: flex-end;
}

.top-header-item {
	height: 100%;
	padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	height: 70px;
}

.top-header-item.border-right:not(:last-child) {
	border-right: 1px solid gainsboro;
}

@media screen and (max-width: 767px) {
	.top-header-item.border-right {
		border-right: none;
		padding: 0.7rem;
	}
}

.top-header-logo {
	height: 100%;
}

.top-header-title {
	font-weight: normal;
}

@media screen and (max-width: 991px) {
	.top-header-title {
		display: none;
	}
}

.top-header-text {
	color: #222;
}

.top-header-content {
	display: flex !important;
	align-items: center;
	padding-bottom: 0 !important;
}

@media screen and (max-width: 991px) {
	.top-header-profile-name {
		display: none;
	}
}

.top-header-profile-image {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.nav-img-container {
	margin-left: 1rem;
	height: 32px;
	width: 32px;
}

.top-header .icon {
	font-size: 1.5rem;
	color: #222;
}

@media screen and (max-width: 991px) {
	.top-header-company-logo {
		display: none;
	}
}
