.dropzone {
	flex: 1 !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	padding: 36px !important;
	border-width: 2px !important;
	border-radius: 2px !important;
	border-color: #eeeeee !important;
	border-style: dashed !important;
	background-color: #fafafa !important;
	/* color: #bdbdbd !important; */
	font-size: 1.25em;
	outline: none !important;
	transition: border 0.24s ease-in-out !important;
	cursor: pointer;
	border-radius: 7px !important;
}

.dropzone input[type='file' i] {
	-webkit-appearance: initial;
	background-color: initial;
	cursor: default;
	align-items: baseline;
	color: inherit;
	text-align: start !important;
	padding: initial;
	border: initial;
	display: none;
}

.dropzone .or-word {
	font-weight: bold;
	font-size: 1em;
	padding: 10px 0;
}

.fileupload-info {
	padding: 8px;
	/* font-size: 0.75em; */
	color: #bdbdbd !important;
}

.info-container {
	padding: 8px;
}

.invalid-container {
	padding: 8px;
	color: #db2828;
}

.dropzone-error {
	font-size: 12px !important;
	padding: 6px;
}

.dropzone-error .icon {
	height: auto !important;
	width: auto !important;
	padding: 1px 5px 1px 1px !important;
	border: none !important;
}

.ui.negative.message.dropzone-error > span {
	display: flex;
	align-items: center;
}

.ui.negative.message.dropzone-error > span > i {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
}

.ui.negative.message.dropzone-error > span > i:first-child {
	margin-right: 3px;
}

.ui.negative.message.dropzone-error > span > i:last-child {
	margin-left: 15px;
}

.custom-upload-icon-text {
	white-space: nowrap;
	font-size: smaller;
	margin: 5px;
}

.ui.message.selected-file {
	display: flex !important;
	align-items: center !important;
	width: 100% !important;
	border-radius: 7px !important;
	opacity: 1 !important;
	padding: 0.8em 1em;
	box-shadow: none !important;
}

.ui.message.file-error span {
	font-size: 1rem !important;
	color: #980000 !important;
}

.ui.message.file-error .alert-icon {
	width: 13px !important;
	height: 13px !important;
	margin-right: 12px !important;
}

.ui.message.file-error {
	border: 0.5px solid #e2c8c8;
	background-color: #fffafa;
	margin-top: 0;
	padding-top: 16px !important;
	padding-bottom: 16px !important;
}

.ui.message.file-success {
	background: #fafdff 0% 0% no-repeat padding-box !important;
	border: 0.5px solid #c8d7e2 !important;
	padding-top: 16px !important;
	padding-bottom: 16px !important;
}

.file-success span {
	margin-left: 12px;
	color: #187bd8 !important;
	font-size: 1rem !important;
}

.file-close {
	margin-left: auto;
	width: 11px !important;
	height: 11px !important;
}

/* .btn-container {
	padding: 0 56px !important;
} */
.btn-select-file {
	width: 100% !important;
	display: block;
	padding: 16px 0 !important;
	box-shadow: none !important;
}

.disabled-img-button {
	opacity: 0.45 !important;
}

.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
