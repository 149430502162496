.company-site-button {
	text-align: right;
	margin-bottom: 1rem;
}
.company-site-button .button {
	margin-right: 0;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.create-site-icon {
	justify-self: center;
	vertical-align: middle;
	align-self: center;
	margin-top: -2px !important;
}
.create-site-text {
	vertical-align: middle;
	justify-self: center;
	align-self: center;
	text-align: center;
}
.company-site-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
}
@media screen and (max-width: 991px) {
	.company-site-list {
		grid-template-columns: 1fr 1fr;
	}
}
@media screen and (max-width: 767px) {
	.company-site-list {
		grid-template-columns: 1fr;
	}
}
.company-site-item {
	box-shadow: 0px 3px 6px #0000001d;
	border-radius: 10px;
	background-color: white;
	position: relative;
	padding-bottom: 30px;
}
.company-site-item .item-content {
	padding: 1.5rem;
}
.company-site-item .item-icon img {
	width: 100%;
}
.company-site-item .item-stat {
	padding: 3px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
}
.company-site-item .item-stat .column {
	margin: 0;
}
.company-site-item .item-stat .column .column {
	padding: 0;
}
.company-site-item .item-stat .column .column:first-child .stat-item {
	border-right: 0.5px solid #aec3d5;
}
.company-site-item .item-stat .stat-item {
	background-color: #eff5fa;
	padding: 1rem;
	text-align: center;
}
.input-search {
	display: block !important;
}

.offices-header {
	font-size: 16px;
}

.site-address {
	color: #6f8191;
}
.company-site-icon {
	width: 40px !important;
	height: 40px !important;
}

.site-title {
	margin: 8px 0 !important;
}

