.setup-welcome {
	height: 100vh;
}
.app-logo-container {
	width: 50px;
	height: 48px;
}
.app-logo {
	width: 100%;
	height: auto;
}
.setup-welcome .card {
	box-shadow: 0px 3px 16px #0000000f;
	background-color: #fff;
	height: 370px;
	border-radius: 10px;
	overflow: hidden;
}
.setup-welcome .card .ui.grid {
	height: 100%;
	margin-top: 0;
}
.setup-welcome-content {
	display: flex !important;
	align-items: center;
}
@media screen and (min-width: 768px) {
	.setup-welcome-content {
		padding-left: 2rem !important;
	}
}
@media screen and (max-width: 767px) {
	.setup-welcome-content {
		text-align: center;
	}
}
.setup-welcome-accent {
	background-color: #0064d8;
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
}

.sub-message {
	font-size: 14px !important;
	padding: 0 5px 0 0 !important;
}

.welcome-start-button {
	font-size: 14px !important;
}
