.trip-scheduler-filter-select {
	min-width: 220px !important;
}

.trip-scheduler-popup-min-width {
	min-width: 500px !important;
}

.custom-popup-right-style {
	position: absolute !important;
	top: -55px !important;
}

.custom-popup-left-style {
	position: absolute !important;
	top: -55px !important;
	right: 0px !important;
}

.p-1-5rem {
	padding: 1.5rem;
}

.custom-table-header {
	line-height: 3;
}

.custom-table-time {
	/* line-height: 3.4; */
	height: 91px;
	/* vertical-align: middle; */
	width: 125px !important;
}

.custom-width-td {
	width: fit-content;

	/* min-height: 55px !important; */
}

.custom-collapse-td {
	height: 55px !important;
}

.custom-span-icon {
	display: flex;
	width: max-content;
	cursor: pointer;
}

.label-assign-driver-vehicle {
	display: block;
	align-items: center;
	color: white;
	font-weight: 300;
	border-radius: 2px;
	padding: 0.5888em 0.833em;
	margin-bottom: 3px;
	min-width: 215px;
	max-width: 215px;
	margin-right: 5px;
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.line-text {
	height: 10px;
	border: dotted 1px #aaa;
	border-right: none;
	border-top: none;
	border-bottom: none;
	margin: 3px;
}

.route-icon {
	margin-right: 10px;
}

.cross-icon {
	margin-top: 3px;
	border: 1px solid #5badfa;
	padding: 5px;
	border-radius: 50%;
	height: 20px;
	float: right;
	margin-right: -20px;
}

.bullet-custom-style {
	margin-right: 5px;
	font-size: x-small;
}

.ui.right.center.popup:before {
	top: 12% !important;
}

.ui.left.center.popup:before {
	top: 12% !important;
}
.icon-tripScheduler-form {
	flex: auto !important;
	text-align: end !important;
}
.table-header-routes {
	min-width: 230px !important;
}

.table-header-time {
	width: 120px !important;
	min-width: 120px !important;
}

.custom-table-padding {
	/* line-height: 3; */
	height: 55px;
}

.view {
	margin: auto;
}

.wrapper {
	position: relative;
	overflow-y: hidden;
	overflow-x: overlay;
}

.sticky-col {
	position: sticky;
	position: -webkit-sticky;
}

.first-col {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	left: 0px;
}

.tag-color:nth-child(3n + 1) {
	background-color: #ed9338;
	/* background-color: #9c5da8; */
	/* background-color: #3899ed; */
}
.tag-color:nth-child(3n + 2) {
	background-color: #3899ed;
	/* background-color: #ed9338; */
}
.tag-color:nth-child(3n + 3) {
	background-color: #9c5da8;
	/* background-color: #3899ed; */
}

/* table list */

.custom-table-list tr th {
	padding: 1rem 1rem;
	vertical-align: middle;
}

.custom-table-list tr td {
	padding-top: 10px;
	padding-right: 20px;
	padding-bottom: 0px !important;
	padding-left: 1rem;
	height: 91px;
	vertical-align: top;
}

.custom-table-list tr th {
	background-color: #3d4e5d !important;
	color: #fff !important;
	text-align: left;
}

.custom-table-list tbody tr:nth-child(odd) td {
	background-color: #fff !important;
}

.custom-table-list tbody tr:nth-child(even) td {
	background-color: #edf3f8 !important;
}

.custom-table-list .field {
	padding-right: 5px !important;
}

.custom-table-list .field .field {
	padding-right: 0 !important;
}

.pb-10 {
	padding-bottom: 10px;
}

.f-right {
	float: right !important;
}

.v-middle {
	vertical-align: middle !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.pb-0 {
	padding-bottom: 0px !important;
}

.f-size-13 {
	font-size: 13px !important;
}

.close-icon {
	margin-top: 2px;
	font-size: xx-small;
	float: right;
	cursor: pointer;
}

.h-10 {
	height: 10px;
}

.delete-trip-modal-content {
	background-color: #f2f7fc;
	text-align: left;
	padding: 10px;
	margin-bottom: 1rem;
}

.note-delete-trip {
	font-size: initial;
	font-family: 'FS Elliot Pro';
}

@media only screen and (min-width: 960px) {
	/* styles for browsers larger than 960px; */
}
