.employee-list-summary {
	display: flex;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 2rem;
}
.employee-list-summary .summary-item {
	padding: 1.5rem;
}
.employee-list-summary .summary-item:first-child {
	background-color: #edf2f5;
}
.employee-list-summary .summary-item:last-child {
	background-color: #fff;
}
.employee-list-summary .summary-item .header {
	margin-top: 0;
}
.employee-list-summary .summary-info {
	color: #707070;
	padding-left: 1rem;
	position: relative;
}

.employee-list-summary .summary-info b {
	color: #000000;
}
.employee-list-summary .summary-info:not(:last-child) {
	margin-bottom: 0.7rem;
}
.employee-list-summary .summary-info::before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	content: '';
	height: 5px;
	width: 5px;
	border-radius: 50%;
}
.employee-list-summary .summary-info.active::before {
	background-color: #19cb5d;
}
.employee-list-summary .summary-info.suspended::before {
	background-color: #fd9346;
}
.employee-status {
	display: flex;
	align-items: center;
	justify-content: end;
}
.employee-status-badge {
	height: 5px;
	width: 5px;
	margin-left: 0px;
	border-radius: 50%;
	margin-right: 5px;
}
.employee-status-suspended {
	background-color: #fd9346;
	border: 1px solid #fd9346;
}
.employee-status-active {
	background-color: #19cb5d;
	border: 1px solid #19cb5d;
}
.employee-form .header {
	margin-bottom: 2.5rem;
}
.employee-form label {
	font-weight: 400 !important;
}
.employee-form .fields {
	margin-bottom: 1.5rem !important;
}
.employee-form-group:not(:last-child) {
	margin-bottom: 4rem;
}
.employee-form-actions {
	margin-top: 1rem;
	margin-bottom: 3rem;
}
.employee-form-actions .button {
	width: 180px;
}
.employee-form-actions .button:not(:last-child) {
	margin-right: 1rem;
}
.employee-form-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}
.employee-form-header .header {
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
}
.employee-form-header .header a {
	margin-top: 0 !important;
}
.employee-form-subheader .status-info {
	margin-left: 1rem;
	padding-left: 1rem;
	position: relative;
}
.employee-form-subheader .status-info::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: 5px;
	width: 5px;
	border-radius: 50%;
}
.employee-form-subheader .status-info--active::before {
	background-color: #19cb5d;
}
.employee-form-modal {
	width: 100%;
	max-width: 600px;
}
.employee-form-modal .content {
	text-align: center;
	padding: 4rem !important;
}
.employee-form-modal .content img {
	width: 120px;
}
.employee-form-modal .content .modal-action {
	margin-top: 2rem;
}
.employee-form-modal .content .modal-action .button:last-child {
	margin-bottom: 0;
}
.view-employee {
	margin-bottom: 3rem;
}
.filter-search-icon {
	position: absolute;
	padding: 10px;
	width: 38px;
	height: 38px;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 4px;
}
.custom-action-edit {
	margin-right: 20px !important;
}
.custom-action-suspend {
	margin-right: 5px !important;
}
.custom-action-remove {
	margin-left: 20px !important;
}
.sub-header {
	font-weight: 500 !important;
	font-size: large !important;
}
.modal-sub-header {
	font-size: initial !important;
	/* font-size: large !important; */
}
/* .custom-full-button {
	min-width: 475px !important;
	padding: 1.1rem !important;
	margin-bottom: 1rem !important;
} */
.input-error-color {
	color: #bb5656 !important;
}

.custom-input-employee {
	border: 0.5px solid #707070 !important;
}

.site-service-list{
	position: relative;
	width: 100%;
	height: 146px;
	margin: 1rem 0;
	padding: 1rem 3rem;
	background:
		#E9F0F7 0% 0% no-repeat padding-box;
	overflow-y: auto;
}

.site-service-list > div{
	position: relative;
	width: 100%;
	height: 100%;
}

.site-service-list ul {
	list-style: inside;
}

.site-service-list ul > li{
	line-height: 2rem;
	text-align: left;
}

.span-asd{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: gainsboro;
	height: 10px;
}

.replace-service-admin-prompt {
	text-align: center;
}

.replace-service-admin-prompt p{
	text-align: left !important;
}