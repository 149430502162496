.login {
  position: relative;
  height: 100vh;
  width: 100vw;
  padding-top: 120px;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .login {
    padding-top: 50px;
  }
}
.login-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 377px;
}
@media screen and (max-width: 991px) {
  .login-left {
    width: 40%;
  }
}
.login-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 722px;
}
@media screen and (max-width: 991px) {
  .login-right {
    width: 60%;
  }
}
.login-item {
  margin-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .login h2.ui.header {
    text-align: center;
    margin-bottom: 3rem !important;
  }
}
@media screen and (max-width: 991px) {
  .login-company-logo {
    text-align: center;
  }
}
.login-company-logo img {
  width: 100px;
  margin-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .login-app-logo {
    text-align: center;
  }
}
.login-app-logo {
  width: 250px;
}
.login-app-logo img {
  width: 250px;
}
@media screen and (max-width: 991px) {
  .login .ui.button {
    width: 100%;
  }
}
.login i.icon {
  color: #0064d8 !important;
}
.login i.error-icon {
  color: #db2828 !important;
}
.login .google-button {
  margin-top: 1rem;
  background-color: #4c7ae3;
  position: relative;
  overflow: hidden;
  color: #fff;
}
.login .google-button:hover, .login .google-button:focus {
  background-color:#4c7ae3 !important;
  color: white;
}
.login .google-button-logo {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  width: 50px;
}
.login .google-button-logo img {
  width: 100%;
}

ul.password-errors {
  list-style-position: inside
}

ul.password-errors > li:before {
  content: "";
  margin-right: -8px;
}

@-moz-document url-prefix() {
  ul.password-errors > li:before {
    content: "";
    margin-right: 0 !important;
  }
}