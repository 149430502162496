.new-site-header-container {
	margin-bottom: 25px;
}

.new-site-title {
	margin-bottom: 15px !important;
}

.header-container {
	padding-bottom: 10px !important;
}
