.status-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.status-text {
	color: #222;
}
.status-badge {
	height: 8px;
	width: 8px;
	margin-left: 0px;
	border-radius: 50%;
	margin-right: 1rem;
}
.status-not-done {
	background-color: #f07800;
	border: 1px solid #f07800;
}
.status-done {
	background-color: #559ff5;
	border: 1px solid #559ff5;
}
.setup-service-disabled {
	text-decoration-line: underline;
	color: #afafaf;
}
.input-class i {
	opacity: 1 !important;
}
.color-textlink {
	color: #5badfa;
}
.m-r20 {
	margin-right: 20px !important;
}
