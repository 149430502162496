@media only screen and (min-width: 768px) {
	.company-detail .table-list-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
@media only screen and (max-width: 767px) {
	.company-detail .table-list-filter .input,
	.company-detail .table-list-filter .button {
		width: 100%;
		margin-bottom: 1rem;
	}
}
.employee-count {
	padding: 1rem;
	border-radius: 10px;
	background-color: #fff;
}
.employee-count-header {
	display: flex;
	align-items: center;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.employee-icon {
	background-color: #559ff5;
	padding: 1rem;
	margin-right: 0.5rem;
	width: 65px;
	height: 65px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.employee-icon img {
	width: 100%;
}

.employee-h2 {
	margin-bottom: 0 !important;
}
.header-margin-bottom {
	margin-bottom: 5px !important;
}

.service-modal {
	width: 100%;
	max-width: 600px;
}

.service-modal .content {
	text-align: center;
	padding: 4rem !important;
}

.service-modal .content img {
	width: 120px;
}

.service-modal .content .modal-action {
	margin-top: 2rem;
}

.service-modal .content .modal-action .button:last-child {
	margin-bottom: 0;
}

.service-toggle-btn {
	margin-top: 25px !important;
}

.service-admin {
	color: #559ff5;
	font-style: italic;
	font-weight: bold;
}
