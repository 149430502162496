.setup-service-complete {
	text-align: center !important;
	padding: 2rem;
}
.setup-service-complete img {
	width: 150px;
}
.setup-service-popup {
	color: #559ff5;
	font-weight: 700;
}
.setup-service-ul {
	list-style-type: disc;
	margin-left: 1rem !important;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	color: white;
}
.fs-elliot {
	font-family: 'FS Elliot Pro', sans-serif !important;
}

.disable-btn {
	opacity: 0.5;
	cursor: default !important;
}

.disable-btn:hover {
	opacity: 0.5;
	color: #5badfa !important;
}

.MuiPaper-root .MuiStepIcon-root text {
	dominant-baseline: text-after-edge;
	font-size: 0.5rem;
	font-weight: 600;
}
/* .MuiPaper-root .MuiStepIcon-root.MuiStepIcon-active text {
	transform: translate(0px, 1px);
} */
