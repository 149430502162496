.new-service-modal.ui.modal {
	text-align: center;
	display: flex !important;
	justify-content: center;
	padding: 3rem 0;
}
.new-service-modal.ui.modal > .content {
	width: auto !important;
	max-width: 450px !important;
}
.new-service-modal.ui.modal > .content .button {
	margin-top: 1rem 0 0 0;
}
.option-container {
	height: auto !important;
}
.service-options {
	padding-top: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 20px;
}

.selection-note {
	margin: 4px 0 8px 0 !important;
	color: #222222 !important;
}

@media screen and (max-width: 767px) {
	.service-options {
		grid-template-columns: 1fr;
	}
}
.service-options .checked.checkbox label {
	font-weight: 700;
}
.service-options .checked.checkbox label::before {
	border-color: #5badfa !important;
}
.service-options .checked.checkbox label::after {
	color: #5badfa !important;
}
.accordion-wrapper {
	margin-top: 1.5rem;
	border-radius: 10px;
	margin-bottom: 1rem;
}
.accordion-wrapper + * {
	margin-top: 0.5em;
}
.accordion-wrapper .dropdown input {
	height: 100%;
}
.dropdown {
	border-radius: 10px !important;
	padding: 1rem 1.5rem !important;
}
.accordion-item {
	transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
	height: auto;
	max-height: 9999px;
	overflow: hidden;
}
.accordion-item.collapsed {
	max-height: 0;
	transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
	background-color: #6f8191;
	font-weight: 600;
	cursor: pointer;
	color: #fff !important;
	border-color: #6f8191;
	padding: 1rem 2.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
}
.accordion-title::after {
	content: '';
	width: 7px;
	height: 7px;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
	transform: rotate(-45deg);
	margin-top: 5px;
}
.accordion-title.open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.accordion-title.open + .accordion-item {
	overflow: visible !important;
}
.accordion-title.open::after {
	content: '';
	transform: rotate(135deg);
}
.accordion-content {
	padding: 1em 1.5em;
	background-color: #e7edf4;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
.accordion-number .spacing {
	width: 30px;
	display: inline-block;
}

.search-loader {
	width: 10px !important;
	height: 10px;
	display: block !important;
	float: right !important;
	top: 0 !important;
	position: absolute !important;
	bottom: 0 !important;
	right: 10px;
	margin: auto !important;
}

.modal-image-container {
	height: 120px;
}

.modal-image-container .modal-image {
	height: 100% !important;
}

/* .sub-name {
  padding: 10px 0
} */

.color-707070 {
	color: #707070;
}

.assignee-grid {
	padding: 2rem 0 !important;
	align-items: center !important;
}

.assignee-padding {
	padding: 0 1rem 0.5rem 1rem !important;
}

.message-content {
	margin-bottom: 2rem;
}
.service-checkbox .checkcontainer {
	padding: 4px 0px 0 28px;
}
