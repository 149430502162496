.semantic-table {
	width: 100%;
	border: none !important;
}

.semantic-table td {
	border: none !important;
}

th {
	white-space: nowrap;
}

.ui.celled.table tbody td.w-50px {
	width: 50px !important;
	max-width: 50px !important;
	min-width: 50px !important;
}

.ui.celled.table tbody td.w-100px {
	width: 100px !important;
	max-width: 100px !important;
	min-width: 100px !important;
}

.ui.celled.table tbody td.w-150px {
	width: 150px !important;
	max-width: 150px !important;
	min-width: 150px !important;
}

.ui.celled.table tbody td.w-200px {
	width: 200px !important;
	min-width: 200px !important;
	max-width: 200px !important;
}

.ui.celled.table tbody td.w-250px {
	width: 250px !important;
	min-width: 250px !important;
	max-width: 250px !important;
}

.ui.celled.table tbody td.w-300px {
	width: 300px !important;
	min-width: 300px !important;
	max-width: 300px !important;
}

.ui.celled.table tbody td.w-350px {
	width: 350px !important;
	min-width: 350px !important;
	max-width: 350px !important;
}

.ui.celled.table tbody td.w-400px {
	width: 400px !important;
	min-width: 400px !important;
	max-width: 400px !important;
}

.ui.celled.table tbody td.w-450px {
	width: 450px !important;
	min-width: 450px !important;
	max-width: 450px !important;
}

.ui.celled.table tbody td.w-500px {
	width: 500px !important;
	min-width: 500px !important;
	max-width: 500px !important;
}

.ui.semantic-table tbody td {
	word-break: break-all !important;
}
.pagination-custom-style {
	position: relative;
	right: 0;
	bottom: 0;
}

.ui.celled.table tr th.sorting-cell {
	padding: 18px 16px 18px 18px !important;
}

table td {
	display: table-cell;
	vertical-align: middle;
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
	.ui.celled.table tbody td.w-50px {
		width: 50px !important;
		max-width: 50px !important;
		min-width: 50px !important;
	}

	.ui.celled.table tbody td.w-100px {
		width: 50px !important;
		max-width: 50px !important;
		min-width: 50px !important;
	}

	.ui.celled.table tbody td.w-150px {
		width: 100px !important;
		max-width: 100px !important;
		min-width: 100px !important;
	}

	.ui.celled.table tbody td.w-200px {
		width: 150px !important;
		min-width: 150px !important;
		max-width: 150px !important;
	}

	.ui.celled.table tbody td.w-250px {
		width: 200px !important;
		min-width: 200px !important;
		max-width: 200px !important;
	}

	.ui.celled.table tbody td.w-300px {
		width: 250px !important;
		min-width: 250px !important;
		max-width: 250px !important;
	}

	.ui.celled.table tbody td.w-350px {
		width: 300px !important;
		min-width: 300px !important;
		max-width: 300px !important;
	}

	.ui.celled.table tbody td.w-400px {
		width: 350px !important;
		min-width: 350px !important;
		max-width: 350px !important;
	}

	.ui.celled.table tbody td.w-450px {
		width: 400px !important;
		min-width: 400px !important;
		max-width: 400px !important;
	}

	.ui.celled.table tbody td.w-500px {
		width: 450px !important;
		min-width: 450px !important;
		max-width: 450px !important;
	}
}

@media only screen and (min-width: 0) and (max-width: 1300px) {
	.ui.celled.table tbody td.w-50px {
		width: auto !important;
		max-width: auto !important;
		min-width: auto !important;
	}

	.ui.celled.table tbody td.w-100px {
		width: auto !important;
		max-width: auto !important;
		min-width: auto !important;
	}

	.ui.celled.table tbody td.w-150px {
		width: auto !important;
		max-width: auto !important;
		min-width: auto !important;
	}

	.ui.celled.table tbody td.w-200px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-250px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-300px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-350px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-400px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-450px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-500px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}
}
