.login-header {
	margin-bottom: 1.5rem;
}
.login-header .header {
	margin-top: 0;
	margin-bottom: 10px;
}
.login-input-small {
	width: 250px !important;
}
.login-bottom-note {
	position: relative;
	margin-top: 2rem;
	padding-top: 10px;
}
.login-bottom-note::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 15px;
	height: 3px;
	background-color: #0053c7;
}
.login-container input,
.login-container button {
	padding: 18px !important;
}
