.button.btn-bulk-print {
	display: inline-block !important;
}

.workdesks-filter .ui.selection.dropdown {
	min-width: 11em !important;
	width: 100% !important;
}
.note-modal {
	font-size: initial;
	font-family: 'FS Elliot Pro';
	color: #989696;
}
.btnLink-disable {
	cursor: default;
	text-decoration: underline;
	color: #989696;
	margin: 3px;
}
.btnLink-disable:hover {
	text-decoration: underline;
	color: #989696;
}

.btn-icon {
	width: 0.797rem;
	height: 12px !important;
}

.custom-width-filter {
	max-width: 159px !important;
}

.btn-download {
	transform: rotate(180deg);
}

.custom-sentence-break {
	white-space: pre-line;
}
.custom-filter-height {
	height: fit-content !important;
}
.custom-margin-right {
	margin-right: 15px;
}
