.basic-information .group-options {
  padding: 0 1.5rem 1.5rem 1.5rem !important;
  background-color: #fafafa;
  border-radius: 5px;
  margin-top: -40px !important;
  margin-left: 28px !important;
}
.image-uploader-container {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .basic-information .group-options {
    margin-left: 1rem !important;
  }
}
.basic-information .label-title {
  margin-bottom: 10px;
}
.basic-information .label-subtitle {
  font-size: 0.9rem;
  color: #6f8191;
}
.basic-information div.icon-holder {
  height: 70px;
  width: 70px;
  border: 0.5px solid #AFAFAF;
  border-radius: 5px;
  padding: 10px;
}
.basic-information .icon-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eff4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.basic-information .icon-image img {
  width: 100%;
}
.basic-information .custom-icon-upload {
  margin-top: 10px;
  display: flex;
}
.basic-information .custom-icon-upload div:first-of-type {
  /* max-width: 80%; */
}
.basic-information .custom-icon-upload .preview-icon {
  margin-left: 10px;
}
.basic-information .default-icon-option {
  margin-left: 32px;
}

.payment-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.php-label {
  margin-right: -36px;
  z-index: 2;
}

.input-payment .ui.input input{
  padding-left: 42px !important;
}