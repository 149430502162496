.site-item:not(:last-child) {
  margin-bottom: 2rem;
}
.add-site {
  color: #5badfa;
  cursor: pointer;
}
.site-padding:not(:first-child) {
  padding: 0rem 2rem 2rem 2rem !important;
}
.site-close {
  /* padding: 1rem 1rem 2rem 1rem; */
  text-align: right;
  margin-right: -30px
  ;
}
.btn-remove {
  background-color: transparent !important;
}

.custom-more-icon {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.custom-close-icon {
  width: 12px;
  height: 12px;
}

.custom-step-highlight {
  padding-top: 0px !important;
}
