.workflow {
	padding-top: 1rem;
	padding-left: 0.5rem;
}
.workflow-item {
	padding-left: 2rem;
	position: relative;
}
.workflow-item:not(:last-child) {
	border-left: 1px solid #0064d8;
	padding-bottom: 3rem;
}
.workflow-item::before {
	content: '';
	position: absolute;
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	left: 0;
	top: 0;
	transform: translate(-50%, -50%);
	background-color: #0064d8;
}
.workflow-item:first-child::before {
	background-color: #004698;
}
.workflow-item .assign-approver {
	padding-top: 2px;
	margin-left: 4rem;
	position: relative;
}
.workflow-item .assign-approver::before {
	content: '';
	position: absolute;
	left: -5px;
	top: 0;
	transform: translate(-100%, 80%);
	height: 10px;
	width: 10px;
	border-left: 1px solid #707070;
	border-bottom: 1px solid #707070;
}
.workflow-content {
	position: relative;
	top: -1.3rem;
}
.workflow-subtitle {
	font-size: 0.9rem;
	color: #6f8191;
}
