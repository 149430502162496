.qrcode-container {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;

	align-items: center;
}

.qrcode {
	width: 105px !important;
	height: 105px !important;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 15px #00000012;
	border-radius: 10px;
	opacity: 1;
	padding: 16px;
	margin: 10px 0;
	display: inline-block;
}

.printqr-anchor {
	display: inline-block;
	margin: 8px !important;
}

.passenger-list-label {
	align-self: flex-end;
	font-size: 20px;
}

.employee-name {
	padding: 4px 0;
}

.sub-name {
	font-size: 12px;
	color: #6F8191;
}
