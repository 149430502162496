.account-setup {
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.page-title {
  margin-top: 50px;
}
.stepper-btn {
  padding: 1.1rem 4rem !important;
}
