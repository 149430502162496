.report-card-action .selection.dropdown.range-options {
	white-space: nowrap;
	width: 300px !important;
}

.booking-bullet {
	color: #19cb5d;
}

.checkedIn-bullet {
	color: #0056bf;
}

.count-label {
	vertical-align: middle !important;
}

.select-status {
	width: auto !important;
	max-width: auto !important;
}
.download-icon {
	width: 13px;
	margin-right: 5px;
}
.reports-dropdown {
	width: 250px !important;
	/* color: 0.5px solid #707070 !important; */
	border: 0.5px solid #707070 !important;
	margin: 0 5px 0 0 !important;
}
