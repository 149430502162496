.service-dashboard-site-inner-tab .ui.tabular.menu {
  border-bottom: none;
}

.service-dashboard-site-inner-tab .ui.tabular.menu .item {
  background: 0 #e4ebf2;
  width: 140px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
  border-radius: .5rem .5rem 0 0 !important;
}

.service-dashboard-site-inner-tab .ui.tabular.menu .active.item {
  background: 0 white !important;
  border-color: transparent;
}

.service-dashboard-site-inner-tab .ui.tab {
  background-color: white;
  padding-bottom: 80px;
  margin-top: 0 !important;
}