.vehicle-filter-select {
	min-width: 190px !important;
}

.icon-vehicle-form {
	flex: auto !important;
	text-align: end !important;
}

.ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
	opacity: 1 !important;
}
