@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-Thin.86101e66.eot);
	src: url(/static/media/FSElliotPro-Thin.86101e66.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-Thin.7fc0dddf.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-Thin.4bb44082.woff)
			format('woff'),
		url(/static/media/FSElliotPro-Thin.1fdd74b5.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-Thin.a722f96e.svg#FSElliotPro-Thin)
			format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-Bold.2faefc16.eot);
	src: url(/static/media/FSElliotPro-Bold.2faefc16.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-Bold.e73db23e.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-Bold.40eaa0bc.woff)
			format('woff'),
		url(/static/media/FSElliotPro-Bold.5d4a2e85.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-Bold.1f209cd8.svg#FSElliotPro-Bold)
			format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-BoldItalic.f658b79e.eot);
	src: url(/static/media/FSElliotPro-BoldItalic.f658b79e.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-BoldItalic.3af4a732.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-BoldItalic.4e6d9edb.woff)
			format('woff'),
		url(/static/media/FSElliotPro-BoldItalic.eab56799.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-BoldItalic.67ad02c1.svg#FSElliotPro-BoldItalic)
			format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro.fd0e75e1.eot);
	src: url(/static/media/FSElliotPro.fd0e75e1.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro.0eb74e2c.woff2) format('woff2'),
		url(/static/media/FSElliotPro.41e161bd.woff) format('woff'),
		url(/static/media/FSElliotPro.0338a283.ttf) format('truetype'),
		url(/static/media/FSElliotPro.109d6c95.svg#FSElliotPro)
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-HeavyItalic.8febd929.eot);
	src: url(/static/media/FSElliotPro-HeavyItalic.8febd929.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-HeavyItalic.2d19d1ad.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-HeavyItalic.ff5f8776.woff)
			format('woff'),
		url(/static/media/FSElliotPro-HeavyItalic.a2c66f54.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-HeavyItalic.dddde741.svg#FSElliotPro-HeavyItalic)
			format('svg');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-Light.13106e13.eot);
	src: url(/static/media/FSElliotPro-Light.13106e13.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-Light.7f6c00b1.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-Light.befce9cc.woff)
			format('woff'),
		url(/static/media/FSElliotPro-Light.8d741c14.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-Light.82c9df38.svg#FSElliotPro-Light)
			format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-LightItalic.a9e71222.eot);
	src: url(/static/media/FSElliotPro-LightItalic.a9e71222.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-LightItalic.4330c50f.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-LightItalic.f6acc48d.woff)
			format('woff'),
		url(/static/media/FSElliotPro-LightItalic.501482c6.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-LightItalic.a5a90ef1.svg#FSElliotPro-LightItalic)
			format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-ThinItalic.7c17d873.eot);
	src: url(/static/media/FSElliotPro-ThinItalic.7c17d873.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-ThinItalic.0222be04.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-ThinItalic.510520bc.woff)
			format('woff'),
		url(/static/media/FSElliotPro-ThinItalic.b3171761.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-ThinItalic.b8dccfcd.svg#FSElliotPro-ThinItalic)
			format('svg');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-Heavy.67e9e6ad.eot);
	src: url(/static/media/FSElliotPro-Heavy.67e9e6ad.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-Heavy.3bc4399f.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-Heavy.dcd4c76a.woff)
			format('woff'),
		url(/static/media/FSElliotPro-Heavy.29ef9ff8.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-Heavy.249c95b6.svg#FSElliotPro-Heavy)
			format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url(/static/media/FSElliotPro-Italic.30c76ab5.eot);
	src: url(/static/media/FSElliotPro-Italic.30c76ab5.eot?#iefix)
			format('embedded-opentype'),
		url(/static/media/FSElliotPro-Italic.64ed8b56.woff2)
			format('woff2'),
		url(/static/media/FSElliotPro-Italic.6783b6f4.woff)
			format('woff'),
		url(/static/media/FSElliotPro-Italic.415e0fff.ttf)
			format('truetype'),
		url(/static/media/FSElliotPro-Italic.49337de3.svg#FSElliotPro-Italic)
			format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

.ui.header {
	font-family: 'FS Elliot Pro', sans-serif;
}

.grey-text {
	color: #6f8191 !important;
}

a {
	color: #5badfa;
}

.ui.button {
	padding: 1.1rem;
	border-radius: 10px !important;
	font-weight: normal;
	font-family: 'FS Elliot Pro', sans-serif;
}

.ui.button.app-primary-button {
	background-color: #5badfa;
	color: #fff;
}

.ui.button.prev,
.ui.button.next {
	min-width: 145px;
}

.ui.button.next {
	align-self: flex-end;
}

.ui.button.app-primary-button:hover {
	background-color: #5badfa !important;
}

.ui.button.app-primary-inverted-button {
	background-color: transparent;
	border: 1px solid #5badfa;
	color: #5badfa;
}

.ui.button.app-primary-inverted-button:hover {
	border-color: #077be8;
}

.ui.button.full-button {
	width: 100%;
	padding: 1.1rem;
	margin-bottom: 1rem;
}

.ui.button.medium-button {
	padding: 1.1rem 4rem;
}

.ui input {
	padding: 1rem 1.5rem !important;
	border-radius: 10px !important;
	font-family: FS Elliot Pro !important;
}

.ui input:focus {
	background-color: #f2f8fd !important;
}

.ui input.input-error {
	border: 1px solid #eeafaf !important;
	background-color: #fffafa !important;
	/* color: #BB5656 !important; */
}

.ui.input {
	/* width: 100% !important; */
}

.ui.label {
	display: flex !important;
	align-items: center !important;
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}

.input-error-message {
	color: #db2828;
	display: block;
	text-align: right;
}

.field {
	width: 100% !important;
}

.ui.selection.dropdown {
	min-width: auto;
	max-height: 49px !important;
}

.grouped.fields {
	margin-bottom: 20px !important;
}

.grouped.fields > label {
	font-size: 1em !important;
}

.stepper-step-container .MuiPaper-root,
.stepper-step-container .MuiTypography-root + div {
	width: 62.5%;
}

.MuiTypography-root + div {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}

.MuiButton-containedPrimary {
	padding: 1.1rem 4rem !important;
	background-color: #5badfa !important;
	box-shadow: none !important;
	border-radius: 10px !important;
}

.MuiButton-containedPrimary:hover {
	background-color: #077be8 !important;
}

.makeStyles-backButton-2 {
	padding: 1.1rem 4rem !important;
	border: 1px solid #5badfa !important;
	background-color: transparent !important;
	box-shadow: none !important;
	border-radius: 10px !important;
	color: #5badfa !important;
}

.makeStyles-backButton-2:hover {
	background-color: #5badfa !important;
	color: #fff !important;
}

.makeStyles-backButton-2.Mui-disabled {
	border: 1px solid gray !important;
	color: gray !important;
}

.stepper-step-container .MuiPaper-root {
	background-color: transparent !important;
	margin-bottom: 2rem;
	display: flex;
	align-items: center !important;
}

.MuiPaper-root .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
	height: 46px;
	width: 46px;
	position: relative;
}

.MuiPaper-root .MuiStepIcon-root {
	height: 46px;
	width: 46px;
	border-radius: 50%;
	background-color: #fff !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.MuiPaper-root .MuiStepIcon-root circle {
	fill: #6f8191;
	r: 9.5;
}

.MuiPaper-root .MuiStepIcon-root text {
	font-size: 0.6rem;
	y: 14 !important;
	transform: translateY(0.7px);
}

.MuiPaper-root .MuiStepIcon-root.MuiStepIcon-active {
	height: 46px;
	width: 46px;
	background-color: #fff;
	border: 1px solid #0064d8;
	position: relative;
}

.MuiPickersModal-dialogRoot {
	width: inherit !important;
	background-color: white !important;
}

.MuiPaper-root .MuiStepIcon-root.MuiStepIcon-active circle {
	fill: #0064d8 !important;
	r: 9.5;
}

.MuiPaper-root .MuiStepIcon-root.MuiStepIcon-completed {
	height: 37px;
	width: 37px;
	color: #0064d8;
}

.MuiStepper-alternativeLabel > .MuiStep-alternativeLabel {
	flex-grow: 0 !important;
	flex-shrink: 0 !important;
	flex-basis: auto !important;
	padding: 0;
}

.MuiStepper-alternativeLabel > .MuiStep-alternativeLabel:not(:first-child) {
	width: 20% !important;
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel:not(:first-child)::after {
	content: '';
	position: absolute;
	top: 52%;
	right: 5px;
	width: 100%;
	transform: translateY(-50%);
	height: 1px;
	background-color: #6f8191;
	z-index: -1;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel
	.MuiStepConnector-alternativeLabel {
	display: none;
}

.MuiStepper-alternativeLabel > .MuiStep-alternativeLabel .MuiStepLabel-root {
	position: relative;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel
	.MuiStepLabel-root
	.MuiStepLabel-labelContainer {
	position: absolute;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel
	.MuiStepLabel-root
	.MuiStepLabel-labelContainer
	.MuiStepLabel-label {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
	background-color: #5badfa !important;
}

.MuiPickersClockPointer-thumb {
	border-color: #5badfa !important;
}

.MuiButton-label {
	color: #5badfa !important;
}

.step-container {
	background-color: #fff;
	border-radius: 10px;
	padding: 2rem;
}

.step-highlight {
	padding: 2rem;
	background-color: #fafafa;
}

* {
	font-family: 'FS Elliot Pro', sans-serif;
}

.element-container {
	padding: 2rem;
	height: 100%;
	margin-bottom: 2rem;
}

.element-container-white {
	background-color: #fff;
}

.element-container-gray {
	background-color: #e9f0f7;
}

.element-header {
	border-bottom: 1px solid gainsboro;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.table-list {
	/* overflow-x: auto; */
}

.table-container {
	overflow-x: auto;
	margin-bottom: 2em;
}

.table-list-filter {
	margin-bottom: 1rem;
	width: 100%;
}

.table-list-filter label {
	white-space: nowrap;
}

.table-list-filter .field {
	width: auto !important;
}

.table-list-filter .ui.selection.dropdown {
	min-width: 14em;
}

.table-list-actions {
	margin-left: auto;
}

.table-list tr th,
.table-list tr td {
	padding: 1rem 2rem !important;
}

.table-list tr th {
	background-color: #3d4e5d !important;
	color: #fff !important;
	text-align: left;
}

.table-list tbody tr:nth-child(odd) td {
	background-color: #fff !important;
}

.table-list tbody tr:nth-child(even) td {
	background-color: #edf3f8 !important;
}

.table-list .field {
	padding-right: 5px !important;
}

.table-list .field .field {
	padding-right: 0 !important;
}

/* .table-list .field .selection.dropdown {
  min-width: 150px;
  width: 150px !important;
} */

/* .table-list .ui.button {
	width: 150px;
	font-weight: 400;
} */
.actions .button:not(:last-child) {
	margin-right: 1rem;
}

.ui.breadcrumb .section {
	color: #6e7c9d;
}

.ui.breadcrumb .active.section {
	color: #222 !important;
}

.page-title {
	margin-bottom: 0 !important;
}

.page-header {
	margin-bottom: 3rem;
	margin-top: 0.14285714em !important;
	font-weight: normal !important;
}

.page-header .page-subtitle {
	font-size: 16px;
}

.character-count {
	color: #6f8191;
	font-size: 0.9rem;
	text-align: right;
}

.character-count-input {
	margin-bottom: 0 !important;
}

.quill .ql-toolbar.ql-snow {
	background-color: #3d4e5d;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.quill .ql-toolbar.ql-snow svg * {
	stroke: #fff !important;
}

.quill .ql-toolbar.ql-snow svg *:hover {
	stroke: #fff;
}

.quill .ql-toolbar.ql-snow .ql-picker-label {
	color: #fff;
}

.quill .ql-toolbar.ql-snow .ql-picker-label:hover {
	color: #fff !important;
}

.quill .ql-container {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.quill .ql-editor {
	min-height: 500px;
	border-bottom: 1px solid #707070 !important;
	border-left: 1px solid #707070 !important;
	border-right: 1px solid #707070 !important;
}

.app-modal.ui.modal {
	padding: 2rem 0;
}

.app-modal.ui.modal .content {
	text-align: center !important;
	padding: 1.5rem 3rem;
}

.app-modal.ui.modal .content img {
	width: 100px;
}

.app-modal.ui.modal .app-primary-button {
	margin-top: 1rem;
}

.vertical-center {
	display: flex !important;
	align-items: center !important;
}

.icon.error {
	color: #db2828 !important;
}

.page-guidelines {
	padding: 2rem 1.5rem;
	background-color: #e9f0f7;
	border-radius: 10px;
}

.page-guidelines .ui.header {
	display: flex;
	align-items: center;
	margin-bottom: 2rem !important;
}

.page-guidelines .ui.header.subtitle {
	margin-bottom: 0.5rem !important;
}

.page-guidelines i.icon {
	font-size: 18px !important;
	position: relative;
}

.page-guidelines i.icon::before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.page-guidelines .bulleted.list .item {
	margin-bottom: 1rem;
}

.page-guidelines .bulleted.list .item::before {
	color: #b3bbc4;
}

.page-guidelines .bulleted.list .item a {
	text-decoration: underline;
}

.page-guidelines .preview {
	width: 100%;
	border-radius: 25px;
}

.overlay {
	z-index: 9999 !important;
	position: fixed !important;
}

.color-black {
	color: black !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

.workflow {
	margin-top: 1rem;
}

.workflow-title {
	font-weight: 700;
}

.content-container {
	padding: 2rem;
	background-color: #fff;
}

.content-container .form {
	width: 100%;
}

.ui.form .field > label {
	/* color: rgba(0,0,0,.87); */
	font-size: 1em !important;
	font-weight: 500 !important;
}

/* .service-radio label {
	text-align: center;
	vertical-align: center;
	align-items: center;
	display: flex !important;
}

.service-radio input[type='checkbox'],
.service-radio input[type='radio'] {
	width: 1.25em !important;
	height: 1.25em !important;
	margin: 0 8px;
} */

.input-check-container {
	margin: 8px 5px 8px 0 !important;
}

.checkcontainer {
	display: revert !important;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	font-size: 1em;
	-webkit-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkcontainer input[type='radio'],
.checkcontainer input[type='checkbox'] {
	position: absolute;
	opacity: 0 !important;
	cursor: pointer;
}
[type='checkbox'],
[type='radio'] {
	padding: 0;
}

/* Create a custom checkbox */
.radiobtn,
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #707070;
}

.checkmark {
	border-radius: 3px !important;
}

.radiobtn {
	border-radius: 50%;
}

/* checked styles of radio and checkbox */
.checkcontainer input:checked ~ .radiobtn {
	background-color: white;
	border: 1px solid #559ff5;
}

.checkcontainer input:checked ~ .checkmark {
	background-color: #559ff5;
	border: none;
}

.checkcontainer input:checked ~ .disabled-checked {
	background-color: #559ff5 !important;
	border: none !important;
	opacity: 0.45 !important;
}

.checkcontainer input[type='radio']:disabled,
.checkcontainer input[type='checkbox']:disabled {
	cursor: default !important;
}

/* disable state of checkbox */
.checkcontainer input:disabled ~ .checkmark,
.checkcontainer input:disabled ~ .radiobtn {
	background-color: #f0f0f0;
	border: none !important;
	cursor: default;
}

.checkcontainer input:disabled ~ span,
.checkcontainer input:disabled ~ span {
	cursor: default !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radiobtn:after,
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked ~ .radiobtn:after,
.checkcontainer input:checked ~ .checkmark:after {
	display: block;
}

.checkcontainer input[type='checkbox'] + span {
	color: #707070;
}

.booking-checkbox.checkcontainer input[type='checkbox'] + span {
	color: #000000;
}

.booking-checkbox.checkcontainer input[type='checkbox']:checked + span {
	font-weight: normal !important;
	color: #000000 !important;
}

/* disabled checkbox font style */
.booking-checkbox.checkcontainer input[type='checkbox']:disabled + span {
	color: #707070 !important;
}

.checkcontainer input[type='checkbox']:checked + span {
	font-weight: bold;
	color: #222222;
}

/* Style the checkmark/indicator */
.checkcontainer .radiobtn:after {
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #5badfa;
}

.checkcontainer .checkmark:after {
	left: 8px;
	top: 4px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.input-pointer {
	margin: 0;
	margin-left: 10px !important;
}

.input-pointer .input > input[type='text'] {
	cursor: pointer;
}

.MuiDialogActions-root {
	width: 100% !important;
}

.ui.basic.button.btn-header,
.ui.basic.button.btn-header:hover,
.ui.basic.button.btn-header:focus {
	box-shadow: none !important;
	padding: 0;
	font-weight: 700;
}

.guidelines-txt {
	margin: 10px 0 0 10px;
}

.idea-img {
	width: auto !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-default {
	cursor: default !important;
}

.setup-welcome .app-primary-button {
	margin-top: 1rem;
}

div.google-visualization-tooltip > ul > li > span {
	color: white !important;
}

div.google-visualization-tooltip {
	background: #6f8191 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
	opacity: 1;
}

svg > g > g:last-child {
	pointer-events: none;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	background-color: #3d4e5d !important;
}

.chart-tooltip {
	color: white !important;
	padding: 16px !important;
}

.chart-tooltip > div > span {
	white-space: nowrap !important;
}

.text-center {
	text-align: center !important;
}

.ui.table tr td:last-child {
	white-space: nowrap;
}

.input-class.filter-searchContainer {
	align-items: center !important;
	max-width: 300px !important;
}

.filter-searchContainer input {
	padding-top: 18px !important;
	padding-bottom: 18px !important;
	font-weight: normal !important;
}

.filter-searchIcon {
	position: absolute;
	width: 38px;
	height: 38px;
	padding: 10px;
	top: 0;
	left: 2px;
	bottom: 0;
	margin: auto;
}

.custom-full-button {
	width: 100% !important;
	margin: 0.5rem 0 !important;
}

.custom-half-button {
	width: 50% !important;
	margin: 0.5rem 0 !important;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
	.ui.container {
		width: 100%;
	}

	.ui.container.page-container {
		padding-bottom: 24px !important;
	}

	.ui.grid.container {
		width: calc(100% + 2rem) !important;
	}

	.ui.relaxed.grid.container {
		width: calc(100% + 3rem) !important;
	}

	.ui.very.relaxed.grid.container {
		width: calc(100% + 5rem) !important;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1608px) {
	.ui.button.app-primary-inverted-button,
	.ui.button.primary,
	.ui.button.app-primary-button {
		min-width: 130px !important;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1800px) {
	.ui.container {
		width: 90%;
	}

	.ui.container.page-container {
		padding-bottom: 24px !important;
	}

	.ui.grid.container {
		width: calc(90% + 2rem) !important;
	}

	.ui.relaxed.grid.container {
		width: calc(90% + 3rem) !important;
	}

	.ui.very.relaxed.grid.container {
		width: calc(90% + 5rem) !important;
	}
}

@media only screen and (min-width: 1800px) {
	.ui.container {
		width: 80%;
	}

	.ui.container.page-container {
		padding-bottom: 24px !important;
	}

	.ui.grid.container {
		width: calc(80% + 2rem) !important;
	}

	.ui.relaxed.grid.container {
		width: calc(80% + 3rem) !important;
	}

	.ui.very.relaxed.grid.container {
		width: calc(80% + 5rem) !important;
	}
	.preview-container {
		padding: 38px 60px !important;
	}
}

.ui.button.app-primary-button:hover {
	background-color: #5badfa !important;
}

.w-100 {
	width: 100% !important;
}

.btnLink {
	cursor: pointer;
	margin: 3px;
	text-decoration: underline;
	color: #5badfa !important;
}

td > .btnLink:first-child {
	margin-left: 0 !important;
}

.btnLink:hover {
	text-decoration: underline;
	color: #5badfa !important;
}

td > .btnLink:first-child {
	margin-left: 0 !important;
}

.filter-select i.icon {
	float: right;
}
i.angle.down.icon {
	margin-right: -5px;
	/* padding-left: 10px; */
}

.ui.search.dropdown > input.search {
	padding-right: 35px !important;
}
.form-grid-row {
	align-items: flex-end !important;
	padding: 0 0 1em !important;
}
.error-message {
	background-color: #ffcbcb !important;
	color: #e55151 !important;
	border: none !important;
	box-shadow: none !important;
	padding-top: 18px !important;
	padding-bottom: 18px !important;
	display: inline-flex;
	align-items: center;
	margin: 5px 0 !important;
}
.error-message img {
	margin-right: 8px;
}
.error-message span {
	font-size: 12px;
	font-weight: 600;
}
.MuiStepIcon-root.MuiStepIcon-completed {
	background-image: radial-gradient(
		white 30%,
		#0064d8 15%,
		#0064d8 60%
	) !important;
	padding: 6px !important;
}

.img-success-message {
	margin-top: 0 !important;
}

.img-success-message > span {
	margin-right: 12px !important;
}

.ungroup-fields.grouped.fields {
	margin-bottom: 0px !important;
}

.margin-subfields {
	margin: -1.25rem 1rem !important;
}

.subfields-margin {
	margin-top: -1em !important;
}
.w-100 {
	width: 100% !important;
}

.w-90 {
	width: 90% !important;
}

.w-95 {
	width: 95% !important;
}

.w-80 {
	width: 80% !important;
}

.w-75 {
	width: 75% !important;
}
.w-50 {
	width: 50% !important;
}

.w-10 {
	width: 10% !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.mh-100 {
	max-height: 100% !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
	margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
	margin-left: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1,
.py-1 {
	padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
	padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pr-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
	padding-left: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

.d-none {
	display: none !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.fw-100 {
	font-weight: 100;
}

.fw-200 {
	font-weight: 200;
}

.fw-300 {
	font-weight: 300;
}

.fw-400 {
	font-weight: 400;
}

.fw-bold {
	font-weight: bold;
}

.time-picker .ui.icon.input > i.icon {
	opacity: 1 !important;
	color: #707070 !important;
}

.btn-link {
	cursor: pointer;
}

.underline {
	text-decoration: underline;
}
.company-details-font {
	color: #6f8191 !important;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}

.MuiPaper-root .MuiStepIcon-root text {
	dominant-baseline: text-after-edge;
	font-size: 0.5rem;
}

.color-subheader {
	color: #919191 !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.ui.page.modals.dimmer.transition.visible.active {
	z-index: 2100;
}

.ui.selection.dropdown .menu>.item {
    word-break: break-all !important;
}

.ui.search.dropdown > .text, .ui.search.selection.dropdown > input.search {
	overflow: hidden;
}

.ui.selection.dropdown {
	word-wrap: unset !important;
}
.login {
  position: relative;
  height: 100vh;
  width: 100vw;
  padding-top: 120px;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .login {
    padding-top: 50px;
  }
}
.login-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 377px;
}
@media screen and (max-width: 991px) {
  .login-left {
    width: 40%;
  }
}
.login-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 722px;
}
@media screen and (max-width: 991px) {
  .login-right {
    width: 60%;
  }
}
.login-item {
  margin-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .login h2.ui.header {
    text-align: center;
    margin-bottom: 3rem !important;
  }
}
@media screen and (max-width: 991px) {
  .login-company-logo {
    text-align: center;
  }
}
.login-company-logo img {
  width: 100px;
  margin-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .login-app-logo {
    text-align: center;
  }
}
.login-app-logo {
  width: 250px;
}
.login-app-logo img {
  width: 250px;
}
@media screen and (max-width: 991px) {
  .login .ui.button {
    width: 100%;
  }
}
.login i.icon {
  color: #0064d8 !important;
}
.login i.error-icon {
  color: #db2828 !important;
}
.login .google-button {
  margin-top: 1rem;
  background-color: #4c7ae3;
  position: relative;
  overflow: hidden;
  color: #fff;
}
.login .google-button:hover, .login .google-button:focus {
  background-color:#4c7ae3 !important;
  color: white;
}
.login .google-button-logo {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  width: 50px;
}
.login .google-button-logo img {
  width: 100%;
}

ul.password-errors {
  list-style-position: inside
}

ul.password-errors > li:before {
  content: "";
  margin-right: -8px;
}

@-moz-document url-prefix() {
  ul.password-errors > li:before {
    content: "";
    margin-right: 0 !important;
  }
}
.loader-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #ffffff;
    z-index: 99999;
}
.img-container, .loader-animation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
} 
.sk-chase {
    width: 30px;
    height: 30px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
    padding: 10px;
}
  
.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}
  
.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #000;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}
  
.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
@keyframes sk-chase {
    100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4); 
    } 100%, 0% {
        transform: scale(1.0); 
    } 
}
.login-header {
	margin-bottom: 1.5rem;
}
.login-header .header {
	margin-top: 0;
	margin-bottom: 10px;
}
.login-input-small {
	width: 250px !important;
}
.login-bottom-note {
	position: relative;
	margin-top: 2rem;
	padding-top: 10px;
}
.login-bottom-note::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 15px;
	height: 3px;
	background-color: #0053c7;
}
.login-container input,
.login-container button {
	padding: 18px !important;
}

.setup-welcome {
	height: 100vh;
}
.app-logo-container {
	width: 50px;
	height: 48px;
}
.app-logo {
	width: 100%;
	height: auto;
}
.setup-welcome .card {
	box-shadow: 0px 3px 16px #0000000f;
	background-color: #fff;
	height: 370px;
	border-radius: 10px;
	overflow: hidden;
}
.setup-welcome .card .ui.grid {
	height: 100%;
	margin-top: 0;
}
.setup-welcome-content {
	display: flex !important;
	align-items: center;
}
@media screen and (min-width: 768px) {
	.setup-welcome-content {
		padding-left: 2rem !important;
	}
}
@media screen and (max-width: 767px) {
	.setup-welcome-content {
		text-align: center;
	}
}
.setup-welcome-accent {
	background-color: #0064d8;
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
}

.sub-message {
	font-size: 14px !important;
	padding: 0 5px 0 0 !important;
}

.welcome-start-button {
	font-size: 14px !important;
}

body {
	background-color: #f4f8fc;
}

.top-header {
	background-color: #fff;
	box-shadow: 0px 3px 16px #00000010;
	padding: 0 1rem;
	height: 70px;
	z-index: 2000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

@media screen and (max-width: 767px) {
	.top-header {
		padding: 0;
	}
}

.top-header .ui.grid {
	margin-top: 0;
}

.top-header-content {
	padding-top: 0 !important;
	display: flex;
	align-items: center;
}

.top-header-content-right {
	justify-content: flex-end;
}

.top-header-item {
	height: 100%;
	padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	height: 70px;
}

.top-header-item.border-right:not(:last-child) {
	border-right: 1px solid gainsboro;
}

@media screen and (max-width: 767px) {
	.top-header-item.border-right {
		border-right: none;
		padding: 0.7rem;
	}
}

.top-header-logo {
	height: 100%;
}

.top-header-title {
	font-weight: normal;
}

@media screen and (max-width: 991px) {
	.top-header-title {
		display: none;
	}
}

.top-header-text {
	color: #222;
}

.top-header-content {
	display: flex !important;
	align-items: center;
	padding-bottom: 0 !important;
}

@media screen and (max-width: 991px) {
	.top-header-profile-name {
		display: none;
	}
}

.top-header-profile-image {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.nav-img-container {
	margin-left: 1rem;
	height: 32px;
	width: 32px;
}

.top-header .icon {
	font-size: 1.5rem;
	color: #222;
}

@media screen and (max-width: 991px) {
	.top-header-company-logo {
		display: none;
	}
}

.nav-menu {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ui.menu.profile-menu a.item:hover {
	background-color: #e9f0f7 !important;
}

.service-menu {
	display: inline-block !important;
}
.service-menu-container {
	width: 95px;
	border-radius: 8px !important;
	display: inline-block !important;
	border: 1px solid white;
}

.service-menu-container:hover,
.service-menu-container.active {
	background-color: #f7fbff !important;
	border: 1px solid #dbe0e5;
	font-weight: 700;
}

.service-menu-item {
	width: 50px;
	height: 50px;
	display: inline-block;
	padding: 16px 10px;
	margin: 10px;
	border-radius: 100px !important;
	background-color: #eff4ff;
	text-align: center;
	justify-content: center;
	/* border: 1px solid white; */
}
.menu.icon {
	width: 20px !important;
}
.service-menu-title {
	word-break: break-word;
	text-align: center;
	color: black;
}

.profile-menu .item {
	padding: 10px 10px !important;
}

.gcheck-toggle-label {
	margin-bottom: 5px;
	font-weight: bold;
	text-align: center;
}

.gcheck-toggle-checkbox-container {
	margin-top: 5px;
	margin: auto;
}

.gcheck-toggle-checkbox {
	display: block !important;
	margin: auto;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
	background-color: #00e600 !important;
}

.healthcheck-modal {
	width: 100%;
	max-width: 600px;
}
.healthcheck-modal .content {
	text-align: center;
	padding: 4rem !important;
}
.healthcheck-modal .content img {
	width: 120px;
}
.healthcheck-modal .content .modal-action {
	margin-top: 2rem;
}
.healthcheck-modal .content .modal-action .button:last-child {
	margin-bottom: 0;
}

.gcheck-toggle-btn {
	margin-top: 25px !important;
}

.company-dashboard {
	padding-top: 190px;
}
a[href*='gaccess-services'] {
	pointer-events: none;
}

a[href*='app-branding'] {
	pointer-events: none;
}

a[href*='app-reports'] {
	pointer-events: none;
}
.navigation {
	height: 80px;
	background-color: #0064d8;
	position: fixed;
	top: 70px;
	left: 0;
	width: 100%;
	z-index: 10;
}
.navigation-content {
	height: 100%;
	display: flex !important;
	align-items: center;
}
.navigation-item {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	color: #fff;
}
.navigation-item:hover {
	color: #fff;
	background-color: #0053b4;
}
.navigation-item--active {
	background-color: #0053b4;
	position: relative;
}
@media screen and (min-width: 768px) {
	.navigation-item--active::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%) rotate(-45deg);
		height: 25px;
		width: 25px;
		background-color: #0053b4;
	}
}

.service-dashboard {
  padding-top: 100px;
  padding-bottom: 50px;
}

.status-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.status-text {
	color: #222;
}
.status-badge {
	height: 8px;
	width: 8px;
	margin-left: 0px;
	border-radius: 50%;
	margin-right: 1rem;
}
.status-not-done {
	background-color: #f07800;
	border: 1px solid #f07800;
}
.status-done {
	background-color: #559ff5;
	border: 1px solid #559ff5;
}
.setup-service-disabled {
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	color: #afafaf;
}
.input-class i {
	opacity: 1 !important;
}
.color-textlink {
	color: #5badfa;
}
.m-r20 {
	margin-right: 20px !important;
}

.semantic-table {
	width: 100%;
	border: none !important;
}

.semantic-table td {
	border: none !important;
}

th {
	white-space: nowrap;
}

.ui.celled.table tbody td.w-50px {
	width: 50px !important;
	max-width: 50px !important;
	min-width: 50px !important;
}

.ui.celled.table tbody td.w-100px {
	width: 100px !important;
	max-width: 100px !important;
	min-width: 100px !important;
}

.ui.celled.table tbody td.w-150px {
	width: 150px !important;
	max-width: 150px !important;
	min-width: 150px !important;
}

.ui.celled.table tbody td.w-200px {
	width: 200px !important;
	min-width: 200px !important;
	max-width: 200px !important;
}

.ui.celled.table tbody td.w-250px {
	width: 250px !important;
	min-width: 250px !important;
	max-width: 250px !important;
}

.ui.celled.table tbody td.w-300px {
	width: 300px !important;
	min-width: 300px !important;
	max-width: 300px !important;
}

.ui.celled.table tbody td.w-350px {
	width: 350px !important;
	min-width: 350px !important;
	max-width: 350px !important;
}

.ui.celled.table tbody td.w-400px {
	width: 400px !important;
	min-width: 400px !important;
	max-width: 400px !important;
}

.ui.celled.table tbody td.w-450px {
	width: 450px !important;
	min-width: 450px !important;
	max-width: 450px !important;
}

.ui.celled.table tbody td.w-500px {
	width: 500px !important;
	min-width: 500px !important;
	max-width: 500px !important;
}

.ui.semantic-table tbody td {
	word-break: break-all !important;
}
.pagination-custom-style {
	position: relative;
	right: 0;
	bottom: 0;
}

.ui.celled.table tr th.sorting-cell {
	padding: 18px 16px 18px 18px !important;
}

table td {
	display: table-cell;
	vertical-align: middle;
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
	.ui.celled.table tbody td.w-50px {
		width: 50px !important;
		max-width: 50px !important;
		min-width: 50px !important;
	}

	.ui.celled.table tbody td.w-100px {
		width: 50px !important;
		max-width: 50px !important;
		min-width: 50px !important;
	}

	.ui.celled.table tbody td.w-150px {
		width: 100px !important;
		max-width: 100px !important;
		min-width: 100px !important;
	}

	.ui.celled.table tbody td.w-200px {
		width: 150px !important;
		min-width: 150px !important;
		max-width: 150px !important;
	}

	.ui.celled.table tbody td.w-250px {
		width: 200px !important;
		min-width: 200px !important;
		max-width: 200px !important;
	}

	.ui.celled.table tbody td.w-300px {
		width: 250px !important;
		min-width: 250px !important;
		max-width: 250px !important;
	}

	.ui.celled.table tbody td.w-350px {
		width: 300px !important;
		min-width: 300px !important;
		max-width: 300px !important;
	}

	.ui.celled.table tbody td.w-400px {
		width: 350px !important;
		min-width: 350px !important;
		max-width: 350px !important;
	}

	.ui.celled.table tbody td.w-450px {
		width: 400px !important;
		min-width: 400px !important;
		max-width: 400px !important;
	}

	.ui.celled.table tbody td.w-500px {
		width: 450px !important;
		min-width: 450px !important;
		max-width: 450px !important;
	}
}

@media only screen and (min-width: 0) and (max-width: 1300px) {
	.ui.celled.table tbody td.w-50px {
		width: auto !important;
		max-width: auto !important;
		min-width: auto !important;
	}

	.ui.celled.table tbody td.w-100px {
		width: auto !important;
		max-width: auto !important;
		min-width: auto !important;
	}

	.ui.celled.table tbody td.w-150px {
		width: auto !important;
		max-width: auto !important;
		min-width: auto !important;
	}

	.ui.celled.table tbody td.w-200px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-250px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-300px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-350px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-400px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-450px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}

	.ui.celled.table tbody td.w-500px {
		width: auto !important;
		min-width: auto !important;
		max-width: auto !important;
	}
}

.color-gray {
    color: #222222 !important;
}

.ui.breadcrumb a:hover {
    color: #6e7c9d;
}
.service-dashboard-site-inner-tab .ui.tabular.menu {
  border-bottom: none;
}

.service-dashboard-site-inner-tab .ui.tabular.menu .item {
  background: 0 #e4ebf2;
  width: 140px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
  border-radius: .5rem .5rem 0 0 !important;
}

.service-dashboard-site-inner-tab .ui.tabular.menu .active.item {
  background: 0 white !important;
  border-color: transparent;
}

.service-dashboard-site-inner-tab .ui.tab {
  background-color: white;
  padding-bottom: 80px;
  margin-top: 0 !important;
}
.dropzone {
	flex: 1 1 !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	padding: 36px !important;
	border-width: 2px !important;
	border-radius: 2px !important;
	border-color: #eeeeee !important;
	border-style: dashed !important;
	background-color: #fafafa !important;
	/* color: #bdbdbd !important; */
	font-size: 1.25em;
	outline: none !important;
	transition: border 0.24s ease-in-out !important;
	cursor: pointer;
	border-radius: 7px !important;
}

.dropzone input[type='file' i] {
	-webkit-appearance: initial;
	background-color: initial;
	cursor: default;
	align-items: baseline;
	color: inherit;
	text-align: start !important;
	padding: initial;
	border: initial;
	display: none;
}

.dropzone .or-word {
	font-weight: bold;
	font-size: 1em;
	padding: 10px 0;
}

.fileupload-info {
	padding: 8px;
	/* font-size: 0.75em; */
	color: #bdbdbd !important;
}

.info-container {
	padding: 8px;
}

.invalid-container {
	padding: 8px;
	color: #db2828;
}

.dropzone-error {
	font-size: 12px !important;
	padding: 6px;
}

.dropzone-error .icon {
	height: auto !important;
	width: auto !important;
	padding: 1px 5px 1px 1px !important;
	border: none !important;
}

.ui.negative.message.dropzone-error > span {
	display: flex;
	align-items: center;
}

.ui.negative.message.dropzone-error > span > i {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
}

.ui.negative.message.dropzone-error > span > i:first-child {
	margin-right: 3px;
}

.ui.negative.message.dropzone-error > span > i:last-child {
	margin-left: 15px;
}

.custom-upload-icon-text {
	white-space: nowrap;
	font-size: smaller;
	margin: 5px;
}

.ui.message.selected-file {
	display: flex !important;
	align-items: center !important;
	width: 100% !important;
	border-radius: 7px !important;
	opacity: 1 !important;
	padding: 0.8em 1em;
	box-shadow: none !important;
}

.ui.message.file-error span {
	font-size: 1rem !important;
	color: #980000 !important;
}

.ui.message.file-error .alert-icon {
	width: 13px !important;
	height: 13px !important;
	margin-right: 12px !important;
}

.ui.message.file-error {
	border: 0.5px solid #e2c8c8;
	background-color: #fffafa;
	margin-top: 0;
	padding-top: 16px !important;
	padding-bottom: 16px !important;
}

.ui.message.file-success {
	background: #fafdff 0% 0% no-repeat padding-box !important;
	border: 0.5px solid #c8d7e2 !important;
	padding-top: 16px !important;
	padding-bottom: 16px !important;
}

.file-success span {
	margin-left: 12px;
	color: #187bd8 !important;
	font-size: 1rem !important;
}

.file-close {
	margin-left: auto;
	width: 11px !important;
	height: 11px !important;
}

/* .btn-container {
	padding: 0 56px !important;
} */
.btn-select-file {
	width: 100% !important;
	display: block;
	padding: 16px 0 !important;
	box-shadow: none !important;
}

.disabled-img-button {
	opacity: 0.45 !important;
}

.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}

.vehicle-filter-select {
	min-width: 190px !important;
}

.icon-vehicle-form {
	flex: auto !important;
	text-align: end !important;
}

.ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
	opacity: 1 !important;
}

/* .drivers-filter-select {
	min-width: 190px !important;
} */

.custom-driver-input {
	border: 0.5px solid #707070 !important;
}


.button.btn-bulk-print {
	display: inline-block !important;
}

.workdesks-filter .ui.selection.dropdown {
	min-width: 11em !important;
	width: 100% !important;
}
.note-modal {
	font-size: initial;
	font-family: 'FS Elliot Pro';
	color: #989696;
}
.btnLink-disable {
	cursor: default;
	text-decoration: underline;
	color: #989696;
	margin: 3px;
}
.btnLink-disable:hover {
	text-decoration: underline;
	color: #989696;
}

.btn-icon {
	width: 0.797rem;
	height: 12px !important;
}

.custom-width-filter {
	max-width: 159px !important;
}

.btn-download {
	transform: rotate(180deg);
}

.custom-sentence-break {
	white-space: pre-line;
}
.custom-filter-height {
	height: -webkit-fit-content !important;
	height: -moz-fit-content !important;
	height: fit-content !important;
}
.custom-margin-right {
	margin-right: 15px;
}

.report-card-action .selection.dropdown.range-options {
	white-space: nowrap;
	width: 300px !important;
}

.booking-bullet {
	color: #19cb5d;
}

.checkedIn-bullet {
	color: #0056bf;
}

.count-label {
	vertical-align: middle !important;
}

.select-status {
	width: auto !important;
	max-width: auto !important;
}
.download-icon {
	width: 13px;
	margin-right: 5px;
}
.reports-dropdown {
	width: 250px !important;
	/* color: 0.5px solid #707070 !important; */
	border: 0.5px solid #707070 !important;
	margin: 0 5px 0 0 !important;
}

.service-builder {
	line-height: 1.6em !important;
}

.service-builder-title {
	display: flex;
	justify-content: space-between;
}

.service-builder-title h3 {
	line-height: 1.1em !important;
}

.service-builder-edit {
	color: #5badfa !important;
	text-decoration: underline;
}

.service-builder-container {
	background-color: #fff;
	padding: 2rem;
	margin-bottom: 1rem;
}

.service-builder-inline {
	display: flex;
}

.service-builder-inline .service-builder-item:not(:last-child) {
	margin-right: 3rem;
}

.service-builder-item:not(:last-child) {
	margin-bottom: 2rem;
}

.service-builder-item .item-title {
	font-weight: 700;
}

.service-builder-item .service-icon {
	border: 1px solid #eee;
	border-radius: 5px;
	position: relative;
	width: 80px;
	height: 80px;
	margin-top: 5px;
}

.service-builder-item .service-icon-image {
	background-color: #eff4ff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
}

.service-builder-item .service-icon-image img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
}

.service-builder-item .item-attachment {
	background-color: #fafdff;
	border: 1px solid #c8d7e2;
	border-radius: 5px;
	padding: 1rem;
	margin-top: 5px;
	width: 100%;
	max-width: 400px;
	display: block;
	color: #222;
}

.service-builder ul {
	padding-left: 2rem;
	margin-top: 5px;
}

.service-builder ul li {
	list-style-type: disc;
}

.item-content {
	padding-top: 8px;
}

.ql-editor li::marker {
	color: transparent;
}

.service-builder .day-checkbox-item label {
	cursor: inherit;
}

.service-dashboard-site-tab .ui.secondary.pointing.menu .item {
	width: 150px;
	justify-content: center;
	border-bottom-width: 4px;
	color: #6f8191;
}

.service-dashboard-site-tab .ui.secondary.pointing.menu .item.active {
	color: #559ff5 !important;
	border-color: #559ff5;
}

.service-dashboard-site-tab .tab {
	background-color: transparent;
	box-shadow: none;
	border: none;
}

.desks-modal {
	width: 100% !important;
	max-width: 500px !important;
	padding: 1.5rem;
}

.desks-modal .header {
	margin-bottom: 1.5rem;
}

.desks-modal .modal-action {
	margin-top: 2rem;
}

.desks-modal .modal-action .button:last-child {
	margin-bottom: 0;
}

.desks-modal .selection.dropdown {
	height: 47px;
	display: flex;
	align-items: center;
}

.bulk-completion-modal-icon,
.single-completion-modal-icon {
	text-align: center;
}

.bulk-completion-modal-icon img,
.single-completion-modal-icon img {
	width: 130px;
}

.report-card {
	background-color: #fff;
	padding: 2rem;
	border-radius: 10px;
	margin-bottom: 2rem;
}

.report-card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.report-card-header .header {
	margin-bottom: 0;
}

.report-card-subtitle {
	display: flex;
	align-items: center;
}

.report-card-subtitle-item {
	padding-left: 1rem;
	position: relative;
	font-size: 0.8rem;
}

.report-card-subtitle-item:not(:last-child) {
	margin-right: 1rem;
}

.report-card-subtitle-item::before {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: '';
	height: 10px;
	width: 10px;
	border-radius: 50%;
}

.report-card-subtitle-item.cancelled::before {
	background-color: #d1504b;
}

.report-card-subtitle-item.checkedin::before {
	background-color: #0056bf;
}

.report-card-action {
	display: flex;
	align-items: center;
}

.report-card-action .selection.dropdown {
	width: 200px;
}

.report-card-action label {
	white-space: nowrap;
	margin-right: 1rem;
}

.report-row {
	padding: 1rem;
	display: flex;
	justify-content: space-between;
}

.report-row:nth-child(odd) {
	background-color: #f2f5f8;
}

.report-row:nth-child(even) {
	background-color: #fafafa;
}

.report-row-item:last-child {
	font-weight: 700;
}

.status-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.status-text {
	color: #222;
}

.status-badge {
	height: 8px;
	width: 8px;
	margin-left: 0px;
	border-radius: 50%;
	margin-right: 1rem;
}

.status-free,
.status-active {
	background-color: #19cb5d;
	border: 1px solid #19cb5d;
}

.status-active {
	background-color: #38c5d1;
	border: 1px solid #38c5d1;
}

.status-inactive {
	background-color: #bdbdbd;
	border: 1px solid #bdbdbd;
}

.status-suspended,
.status-dismissed {
	background-color: #d13838;
	border: 1px solid #d13838;
}

.status-occupied {
	background-color: #cb2719;
	border: 1px solid #cb2719;
}

/* override filter input margin */
.slot-search-filter {
	display: flex !important;
	justify-content: space-between;
}

.slot-search-filter.ui.search.dropdown > .text,
.slot-search-filter.ui.search.selection.dropdown > input.search {
	margin-left: 0 !important;
	width: inherit;
	margin-right: 5px;
}

.shared-slot-name {
	font-size: smaller;
	color: #6f8191;
	font-weight: 500;
}

.margin-left-shared-label {
	margin-left: 1.5rem;
}

.active-shared-status {
	background-color: #19cb5d;
	border: 1px solid #19cb5d;
}
.font-weight-normal {
	font-weight: normal !important;
}

.trip-scheduler-filter-select {
	min-width: 220px !important;
}

.trip-scheduler-popup-min-width {
	min-width: 500px !important;
}

.custom-popup-right-style {
	position: absolute !important;
	top: -55px !important;
}

.custom-popup-left-style {
	position: absolute !important;
	top: -55px !important;
	right: 0px !important;
}

.p-1-5rem {
	padding: 1.5rem;
}

.custom-table-header {
	line-height: 3;
}

.custom-table-time {
	/* line-height: 3.4; */
	height: 91px;
	/* vertical-align: middle; */
	width: 125px !important;
}

.custom-width-td {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;

	/* min-height: 55px !important; */
}

.custom-collapse-td {
	height: 55px !important;
}

.custom-span-icon {
	display: flex;
	width: -webkit-max-content;
	width: max-content;
	cursor: pointer;
}

.label-assign-driver-vehicle {
	display: block;
	align-items: center;
	color: white;
	font-weight: 300;
	border-radius: 2px;
	padding: 0.5888em 0.833em;
	margin-bottom: 3px;
	min-width: 215px;
	max-width: 215px;
	margin-right: 5px;
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.line-text {
	height: 10px;
	border: dotted 1px #aaa;
	border-right: none;
	border-top: none;
	border-bottom: none;
	margin: 3px;
}

.route-icon {
	margin-right: 10px;
}

.cross-icon {
	margin-top: 3px;
	border: 1px solid #5badfa;
	padding: 5px;
	border-radius: 50%;
	height: 20px;
	float: right;
	margin-right: -20px;
}

.bullet-custom-style {
	margin-right: 5px;
	font-size: x-small;
}

.ui.right.center.popup:before {
	top: 12% !important;
}

.ui.left.center.popup:before {
	top: 12% !important;
}
.icon-tripScheduler-form {
	flex: auto !important;
	text-align: end !important;
}
.table-header-routes {
	min-width: 230px !important;
}

.table-header-time {
	width: 120px !important;
	min-width: 120px !important;
}

.custom-table-padding {
	/* line-height: 3; */
	height: 55px;
}

.view {
	margin: auto;
}

.wrapper {
	position: relative;
	overflow-y: hidden;
	overflow-x: overlay;
}

.sticky-col {
	position: sticky;
	position: -webkit-sticky;
}

.first-col {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	left: 0px;
}

.tag-color:nth-child(3n + 1) {
	background-color: #ed9338;
	/* background-color: #9c5da8; */
	/* background-color: #3899ed; */
}
.tag-color:nth-child(3n + 2) {
	background-color: #3899ed;
	/* background-color: #ed9338; */
}
.tag-color:nth-child(3n + 3) {
	background-color: #9c5da8;
	/* background-color: #3899ed; */
}

/* table list */

.custom-table-list tr th {
	padding: 1rem 1rem;
	vertical-align: middle;
}

.custom-table-list tr td {
	padding-top: 10px;
	padding-right: 20px;
	padding-bottom: 0px !important;
	padding-left: 1rem;
	height: 91px;
	vertical-align: top;
}

.custom-table-list tr th {
	background-color: #3d4e5d !important;
	color: #fff !important;
	text-align: left;
}

.custom-table-list tbody tr:nth-child(odd) td {
	background-color: #fff !important;
}

.custom-table-list tbody tr:nth-child(even) td {
	background-color: #edf3f8 !important;
}

.custom-table-list .field {
	padding-right: 5px !important;
}

.custom-table-list .field .field {
	padding-right: 0 !important;
}

.pb-10 {
	padding-bottom: 10px;
}

.f-right {
	float: right !important;
}

.v-middle {
	vertical-align: middle !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.pb-0 {
	padding-bottom: 0px !important;
}

.f-size-13 {
	font-size: 13px !important;
}

.close-icon {
	margin-top: 2px;
	font-size: xx-small;
	float: right;
	cursor: pointer;
}

.h-10 {
	height: 10px;
}

.delete-trip-modal-content {
	background-color: #f2f7fc;
	text-align: left;
	padding: 10px;
	margin-bottom: 1rem;
}

.note-delete-trip {
	font-size: initial;
	font-family: 'FS Elliot Pro';
}

@media only screen and (min-width: 960px) {
	/* styles for browsers larger than 960px; */
}

.sub-title-shuttle-date {
	/* margin: 2rem 0px 2rem 0px; */
	margin-top: 1.8rem;
	margin-bottom: 1.5rem;
	font-weight: 600;
	font-size: 16px;
}

.custom-no-available-trips {
	/* font-weight: 600; */
	font-size: 16px;
}

.mb-1rem {
	margin-bottom: 1.5rem;
}

.space-no-wrap {
	white-space: nowrap;
}

.edit-trip-modal-content {
	background-color: #f2f7fc;
	/* text-align: center; */
	padding: 10px;
	margin-bottom: 1rem;
}

.disable-anchor-tag {
	pointer-events: none;
	/* text-decoration: underline; */
	color: #989696 !important;
}

.qrcode-container {
	display: flex;

	align-items: center;
}

.qrcode {
	width: 105px !important;
	height: 105px !important;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 15px #00000012;
	border-radius: 10px;
	opacity: 1;
	padding: 16px;
	margin: 10px 0;
	display: inline-block;
}

.printqr-anchor {
	display: inline-block;
	margin: 8px !important;
}

.passenger-list-label {
	align-self: flex-end;
	font-size: 20px;
}

.employee-name {
	padding: 4px 0;
}

.sub-name {
	font-size: 12px;
	color: #6F8191;
}

.employee-list-summary {
	display: flex;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 2rem;
}
.employee-list-summary .summary-item {
	padding: 1.5rem;
}
.employee-list-summary .summary-item:first-child {
	background-color: #edf2f5;
}
.employee-list-summary .summary-item:last-child {
	background-color: #fff;
}
.employee-list-summary .summary-item .header {
	margin-top: 0;
}
.employee-list-summary .summary-info {
	color: #707070;
	padding-left: 1rem;
	position: relative;
}

.employee-list-summary .summary-info b {
	color: #000000;
}
.employee-list-summary .summary-info:not(:last-child) {
	margin-bottom: 0.7rem;
}
.employee-list-summary .summary-info::before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	content: '';
	height: 5px;
	width: 5px;
	border-radius: 50%;
}
.employee-list-summary .summary-info.active::before {
	background-color: #19cb5d;
}
.employee-list-summary .summary-info.suspended::before {
	background-color: #fd9346;
}
.employee-status {
	display: flex;
	align-items: center;
	justify-content: end;
}
.employee-status-badge {
	height: 5px;
	width: 5px;
	margin-left: 0px;
	border-radius: 50%;
	margin-right: 5px;
}
.employee-status-suspended {
	background-color: #fd9346;
	border: 1px solid #fd9346;
}
.employee-status-active {
	background-color: #19cb5d;
	border: 1px solid #19cb5d;
}
.employee-form .header {
	margin-bottom: 2.5rem;
}
.employee-form label {
	font-weight: 400 !important;
}
.employee-form .fields {
	margin-bottom: 1.5rem !important;
}
.employee-form-group:not(:last-child) {
	margin-bottom: 4rem;
}
.employee-form-actions {
	margin-top: 1rem;
	margin-bottom: 3rem;
}
.employee-form-actions .button {
	width: 180px;
}
.employee-form-actions .button:not(:last-child) {
	margin-right: 1rem;
}
.employee-form-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}
.employee-form-header .header {
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
}
.employee-form-header .header a {
	margin-top: 0 !important;
}
.employee-form-subheader .status-info {
	margin-left: 1rem;
	padding-left: 1rem;
	position: relative;
}
.employee-form-subheader .status-info::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: 5px;
	width: 5px;
	border-radius: 50%;
}
.employee-form-subheader .status-info--active::before {
	background-color: #19cb5d;
}
.employee-form-modal {
	width: 100%;
	max-width: 600px;
}
.employee-form-modal .content {
	text-align: center;
	padding: 4rem !important;
}
.employee-form-modal .content img {
	width: 120px;
}
.employee-form-modal .content .modal-action {
	margin-top: 2rem;
}
.employee-form-modal .content .modal-action .button:last-child {
	margin-bottom: 0;
}
.view-employee {
	margin-bottom: 3rem;
}
.filter-search-icon {
	position: absolute;
	padding: 10px;
	width: 38px;
	height: 38px;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 4px;
}
.custom-action-edit {
	margin-right: 20px !important;
}
.custom-action-suspend {
	margin-right: 5px !important;
}
.custom-action-remove {
	margin-left: 20px !important;
}
.sub-header {
	font-weight: 500 !important;
	font-size: large !important;
}
.modal-sub-header {
	font-size: initial !important;
	/* font-size: large !important; */
}
/* .custom-full-button {
	min-width: 475px !important;
	padding: 1.1rem !important;
	margin-bottom: 1rem !important;
} */
.input-error-color {
	color: #bb5656 !important;
}

.custom-input-employee {
	border: 0.5px solid #707070 !important;
}

.site-service-list{
	position: relative;
	width: 100%;
	height: 146px;
	margin: 1rem 0;
	padding: 1rem 3rem;
	background:
		#E9F0F7 0% 0% no-repeat padding-box;
	overflow-y: auto;
}

.site-service-list > div{
	position: relative;
	width: 100%;
	height: 100%;
}

.site-service-list ul {
	list-style: inside;
}

.site-service-list ul > li{
	line-height: 2rem;
	text-align: left;
}

.span-asd{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: gainsboro;
	height: 10px;
}

.replace-service-admin-prompt {
	text-align: center;
}

.replace-service-admin-prompt p{
	text-align: left !important;
}
.company-dashboard {
  padding-top: 190px;
}
.navigation {
  height: 80px;
  background-color: #0064d8;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 10;
}
.navigation-content {
  height: 100%;
  display: flex !important;
  align-items: center;
}
.navigation-item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: #fff;
}
.navigation-item:hover {
  color: #fff;
  background-color: #0053b4;
}
.navigation-item--active {
  background-color: #0053b4;
  position: relative;
}
@media screen and (min-width: 768px) {
  .navigation-item--active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(-45deg);
    height: 25px;
    width: 25px;
    background-color: #0053b4;
  }
}

.setup-service-complete {
	text-align: center !important;
	padding: 2rem;
}
.setup-service-complete img {
	width: 150px;
}
.setup-service-popup {
	color: #559ff5;
	font-weight: 700;
}
.setup-service-ul {
	list-style-type: disc;
	margin-left: 1rem !important;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	color: white;
}
.fs-elliot {
	font-family: 'FS Elliot Pro', sans-serif !important;
}

.disable-btn {
	opacity: 0.5;
	cursor: default !important;
}

.disable-btn:hover {
	opacity: 0.5;
	color: #5badfa !important;
}

.MuiPaper-root .MuiStepIcon-root text {
	dominant-baseline: text-after-edge;
	font-size: 0.5rem;
	font-weight: 600;
}
/* .MuiPaper-root .MuiStepIcon-root.MuiStepIcon-active text {
	transform: translate(0px, 1px);
} */

.basic-information .group-options {
  padding: 0 1.5rem 1.5rem 1.5rem !important;
  background-color: #fafafa;
  border-radius: 5px;
  margin-top: -40px !important;
  margin-left: 28px !important;
}
.image-uploader-container {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .basic-information .group-options {
    margin-left: 1rem !important;
  }
}
.basic-information .label-title {
  margin-bottom: 10px;
}
.basic-information .label-subtitle {
  font-size: 0.9rem;
  color: #6f8191;
}
.basic-information div.icon-holder {
  height: 70px;
  width: 70px;
  border: 0.5px solid #AFAFAF;
  border-radius: 5px;
  padding: 10px;
}
.basic-information .icon-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eff4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.basic-information .icon-image img {
  width: 100%;
}
.basic-information .custom-icon-upload {
  margin-top: 10px;
  display: flex;
}
.basic-information .custom-icon-upload div:first-of-type {
  /* max-width: 80%; */
}
.basic-information .custom-icon-upload .preview-icon {
  margin-left: 10px;
}
.basic-information .default-icon-option {
  margin-left: 32px;
}

.payment-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.php-label {
  margin-right: -36px;
  z-index: 2;
}

.input-payment .ui.input input{
  padding-left: 42px !important;
}
.ql-container {
	max-height: 501px;
	overflow-y: auto;
	font-size: 14px;
	color: #070707;
	font-family: 'FS Elliot Pro', sans-serif !important;
}

.overflow-none {
	overflow: hidden;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: attr(data-value) !important;
}

.advance-booking-fields {
	padding-left: 26px;
}
.booking-upload-field {
	background-color: #fcfcfc;
	padding: 1rem;
	border: 1px solid #707070;
	border-radius: 10px;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	width: 80% !important;
}
.booking-upload-field ul {
	padding-left: 1rem;
	margin-bottom: 0.5rem;
}
.booking-upload-field li {
	list-style-type: disc;
}
.subfields-group {
	padding-left: 33px;
}
.day-checkbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 85%;
}
.day-checkbox-item label {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #707070;
	padding: 5px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	/* color: #222; */
	cursor: pointer;
}
.day-checkbox-item label::before,
.day-checkbox-item label::after {
	display: none;
}
.day-checkbox-item input {
	display: none !important;
}
.day-checkbox-item.checked label {
	background-color: #559ff5 !important;
	color: #fff !important;
	border-color: #559ff5 !important;
}

.form-group {
	margin: 0 0 40px 0 !important;
}
.schedule-container {
	padding: 0.5rem 0 !important;
}
.similar-across-timer {
	/* padding-left: 1.25em !important; */
	padding-left: 11px !important;
	margin-top: -1em !important;
}
.booking-grid {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.ui.labeled.input > .label:not(.corner) {
	border-left: 0.5px solid #707070 !important;
}

/* Hide arrows in input type=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield !important;
}

#custom-limited-input {
	background-color: #dadada;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border: solid 0.25px #707070 !important;
	min-width: 130px !important;
	font-weight: 500 !important;
}

.custom-limited-icon {
	position: absolute;
	right: 10px;
}

.w70 {
	width: 110% !important;
	/* min-width: 50% !important; */
}
.sub-text {
	color: #6f8191;
}
.custom-label-white-space {
	white-space: nowrap;
}

.white-space-pre {
	white-space: pre;
}

.workflow {
	padding-top: 1rem;
	padding-left: 0.5rem;
}
.workflow-item {
	padding-left: 2rem;
	position: relative;
}
.workflow-item:not(:last-child) {
	border-left: 1px solid #0064d8;
	padding-bottom: 3rem;
}
.workflow-item::before {
	content: '';
	position: absolute;
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	left: 0;
	top: 0;
	transform: translate(-50%, -50%);
	background-color: #0064d8;
}
.workflow-item:first-child::before {
	background-color: #004698;
}
.workflow-item .assign-approver {
	padding-top: 2px;
	margin-left: 4rem;
	position: relative;
}
.workflow-item .assign-approver::before {
	content: '';
	position: absolute;
	left: -5px;
	top: 0;
	transform: translate(-100%, 80%);
	height: 10px;
	width: 10px;
	border-left: 1px solid #707070;
	border-bottom: 1px solid #707070;
}
.workflow-content {
	position: relative;
	top: -1.3rem;
}
.workflow-subtitle {
	font-size: 0.9rem;
	color: #6f8191;
}

.preview-container {
	background-color: #e9f0f7;
	padding: 38px 40px;
}

.preview-container h5,
.preview-container h5,
.preview-container h4,
.preview-container h3,
.preview-container h2,
.preview-container h1 {
	font-family: 'FS Elliot Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.preview-container-title {
	font-size: 16px;
	font-weight: bold;
}

.preview-container-text {
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 1.5rem;
}

.setup-preview {
	background-color: #f5faff;
	width: 100%;
	border-radius: 25px;
	box-shadow: 0px 3px 16px #00000024;
	overflow: hidden;
}

.setup-preview div,
.setup-preview p,
.setup-preview a {
	font-size: 10px;
	line-height: 14px;
}

.setup-preview .ui.segment {
	font-size: 10px;
}

.setup-preview-header {
	background-color: #6f8191;
	height: 60px;
}

.setup-preview-body {
	padding: 20px;
}

.setup-preview-title {
	font-size: 1.3em !important;
	font-weight: 700;
	margin-bottom: 0.5rem;
	word-wrap: break-word;
}

.setup-preview-text {
	word-wrap: break-word;
}

.service-preview-text-small {
	font-size: 8px;
}

.setup-preview-tab {
	margin-top: 25px;
}

.setup-preview-tab .tabular.menu {
	background-color: #cddae6;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	font-size: 0;
}

.setup-preview-tab .tabular.menu .item {
	width: 50%;
	color: #6f8191;
}

.setup-preview-tab .tabular.menu .item.active {
	border: none;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	position: relative;
}

.setup-preview-tab .tabular.menu .item:first-child.active::before {
	display: block;
	position: absolute;
	content: '';
	right: 0;
	top: 100% !important;
	transform: translate(100%, -100%);
	height: 10px;
	width: 10px;
	background-color: #fff;
	border-top: 1px solid #cddae6;
	border-right: 1px solid #cddae6;
}

.setup-preview-tab .tabular.menu .item:first-child.active::after {
	position: absolute;
	content: '';
	right: 0;
	bottom: 0;
	transform: translateX(100%);
	height: 10px;
	width: 10px;
	border-bottom-left-radius: 10px;
	background-color: #cddae6;
	z-index: 2;
}

.setup-preview-tab .tabular.menu .item:last-child.active::before {
	display: block;
	position: absolute;
	content: '';
	left: 0;
	top: 100% !important;
	transform: translate(-100%, -100%);
	height: 10px;
	width: 10px;
	background-color: #fff;
	border-top: 1px solid #cddae6;
	border-left: 1px solid #cddae6;
}

.setup-preview-tab .tabular.menu .item:last-child.active::after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	transform: translateX(-100%);
	height: 10px;
	width: 10px;
	border-bottom-right-radius: 10px;
	background-color: #cddae6;
	z-index: 2;
}

.setup-preview-tab .ui.segment {
	border: none !important;
	padding: 20px 15px;
	border-radius: 10pz;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	box-shadow: none !important;
}

.service-preview-item {
	margin-bottom: 12px;
}

.service-preview-item label {
	display: block;
	margin-bottom: 5px !important;
}

.setup-preview-button {
	background-color: #070707 !important;
	font-size: 10px !important;
	color: #fff !important;
	width: 100%;
	padding: 12px !important;
	font-weight: 400 !important;
	position: absolute;
	bottom: 20px;
	left: 0;
}

.setup-preview-tab-content {
	min-height: 400px;
	position: relative;
	width: 100%;
	/* word-wrap: break-word; */
	max-height: 400px;
	overflow-y: auto;
	word-break: break-all;
	overflow-x: hidden;
}

.service-preview-item-title {
	font-size: 1.5em !important;
	line-height: 20px;
	margin-bottom: 15px;
}

.service-preview-item .ui.selection.dropdown {
	padding: 11px !important;
	position: relative;
}

.service-preview-item .ui.selection.dropdown i {
	position: absolute;
	top: 75% !important;
	right: 11px;
	transform: translateY(-50%);
}

.service-preview-item input {
	padding: 13px !important;
}

.time-slots {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
	column-gap: 20px;
	grid-row-gap: 10px;
	row-gap: 10px;
}

.time-slots-item {
	background-color: #6f8191;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
}

.time-slots-item div {
	color: inherit;
	line-height: 1em;
}
.service-preview-item-content p {
	margin: 0 !important;
}
.service-preview-item-content ol,
.service-preview-item-content ul {
	padding-left: 2.5em !important;
}

.service-preview-item-content ol {
	list-style-type: decimal;
	list-style-position: inside;
}

.service-preview-item-content ul {
	list-style-type: disc;
	list-style-position: inside;
}

.service-preview-item-content h1 {
	font-size: 18px !important;
}
.service-preview-item-content h2 {
	font-size: 16px !important;
}
.service-preview-item-content h3 {
	font-size: 14px !important;
}
.service-preview-item-content h4 {
	font-size: 13px !important;
}
.service-preview-item-content h5 {
	font-size: 12px !important;
}
.service-preview-item-content h6 {
	font-size: 10px !important;
}
.service-preview-item-content .ql-align-center {
	text-align: center !important;
}
.service-preview-item-content .ql-align-right {
	text-align: right !important;
}
.service-preview-item-content .ql-align-justify {
	text-align: justify !important;
}
img.origin-icon {
	width: 30px !important;
	height: auto;
	z-index: 99999999;
	margin: 26.5px 8px;
	padding: 8px;
}

img.destination-icon {
	width: 30px !important;
	height: auto;
	z-index: 99999999;
	margin: 5px 8px 19px 8px;
	padding: 8px;
}

div.border-dotted {
	left: 22px;
	top: 49px;
	border-right: 2px dotted #6e7c9d;
	height: 50px !important;
	position: absolute;
	padding: 0;
}

.account-setup {
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.page-title {
  margin-top: 50px;
}
.stepper-btn {
  padding: 1.1rem 4rem !important;
}

.employee-list {
	min-height: 400px;
}
.employee-list-note {
	margin-bottom: 24px;
}
.file-error-message {
	background-color: #ffcbcb !important;
	border: none;
	color: #e55151 !important;
	box-shadow: none !important;
	font-size: 0.9em !important;
	font-weight: 600 !important;
	/* display: inline-block !important; */
}

.site-item:not(:last-child) {
  margin-bottom: 2rem;
}
.add-site {
  color: #5badfa;
  cursor: pointer;
}
.site-padding:not(:first-child) {
  padding: 0rem 2rem 2rem 2rem !important;
}
.site-close {
  /* padding: 1rem 1rem 2rem 1rem; */
  text-align: right;
  margin-right: -30px
  ;
}
.btn-remove {
  background-color: transparent !important;
}

.custom-more-icon {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.custom-close-icon {
  width: 12px;
  height: 12px;
}

.custom-step-highlight {
  padding-top: 0px !important;
}

.company-site-button {
	text-align: right;
	margin-bottom: 1rem;
}
.company-site-button .button {
	margin-right: 0;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.create-site-icon {
	justify-self: center;
	vertical-align: middle;
	align-self: center;
	margin-top: -2px !important;
}
.create-site-text {
	vertical-align: middle;
	justify-self: center;
	align-self: center;
	text-align: center;
}
.company-site-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 10px;
	column-gap: 10px;
	grid-row-gap: 10px;
	row-gap: 10px;
}
@media screen and (max-width: 991px) {
	.company-site-list {
		grid-template-columns: 1fr 1fr;
	}
}
@media screen and (max-width: 767px) {
	.company-site-list {
		grid-template-columns: 1fr;
	}
}
.company-site-item {
	box-shadow: 0px 3px 6px #0000001d;
	border-radius: 10px;
	background-color: white;
	position: relative;
	padding-bottom: 30px;
}
.company-site-item .item-content {
	padding: 1.5rem;
}
.company-site-item .item-icon img {
	width: 100%;
}
.company-site-item .item-stat {
	padding: 3px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
}
.company-site-item .item-stat .column {
	margin: 0;
}
.company-site-item .item-stat .column .column {
	padding: 0;
}
.company-site-item .item-stat .column .column:first-child .stat-item {
	border-right: 0.5px solid #aec3d5;
}
.company-site-item .item-stat .stat-item {
	background-color: #eff5fa;
	padding: 1rem;
	text-align: center;
}
.input-search {
	display: block !important;
}

.offices-header {
	font-size: 16px;
}

.site-address {
	color: #6f8191;
}
.company-site-icon {
	width: 40px !important;
	height: 40px !important;
}

.site-title {
	margin: 8px 0 !important;
}


@media only screen and (min-width: 768px) {
	.company-detail .table-list-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
@media only screen and (max-width: 767px) {
	.company-detail .table-list-filter .input,
	.company-detail .table-list-filter .button {
		width: 100%;
		margin-bottom: 1rem;
	}
}
.employee-count {
	padding: 1rem;
	border-radius: 10px;
	background-color: #fff;
}
.employee-count-header {
	display: flex;
	align-items: center;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.employee-icon {
	background-color: #559ff5;
	padding: 1rem;
	margin-right: 0.5rem;
	width: 65px;
	height: 65px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.employee-icon img {
	width: 100%;
}

.employee-h2 {
	margin-bottom: 0 !important;
}
.header-margin-bottom {
	margin-bottom: 5px !important;
}

.service-modal {
	width: 100%;
	max-width: 600px;
}

.service-modal .content {
	text-align: center;
	padding: 4rem !important;
}

.service-modal .content img {
	width: 120px;
}

.service-modal .content .modal-action {
	margin-top: 2rem;
}

.service-modal .content .modal-action .button:last-child {
	margin-bottom: 0;
}

.service-toggle-btn {
	margin-top: 25px !important;
}

.service-admin {
	color: #559ff5;
	font-style: italic;
	font-weight: bold;
}

.new-service-modal.ui.modal {
	text-align: center;
	display: flex !important;
	justify-content: center;
	padding: 3rem 0;
}
.new-service-modal.ui.modal > .content {
	width: auto !important;
	max-width: 450px !important;
}
.new-service-modal.ui.modal > .content .button {
	margin-top: 1rem 0 0 0;
}
.option-container {
	height: auto !important;
}
.service-options {
	padding-top: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 10px;
	column-gap: 10px;
	grid-row-gap: 20px;
	row-gap: 20px;
}

.selection-note {
	margin: 4px 0 8px 0 !important;
	color: #222222 !important;
}

@media screen and (max-width: 767px) {
	.service-options {
		grid-template-columns: 1fr;
	}
}
.service-options .checked.checkbox label {
	font-weight: 700;
}
.service-options .checked.checkbox label::before {
	border-color: #5badfa !important;
}
.service-options .checked.checkbox label::after {
	color: #5badfa !important;
}
.accordion-wrapper {
	margin-top: 1.5rem;
	border-radius: 10px;
	margin-bottom: 1rem;
}
.accordion-wrapper + * {
	margin-top: 0.5em;
}
.accordion-wrapper .dropdown input {
	height: 100%;
}
.dropdown {
	border-radius: 10px !important;
	padding: 1rem 1.5rem !important;
}
.accordion-item {
	transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
	height: auto;
	max-height: 9999px;
	overflow: hidden;
}
.accordion-item.collapsed {
	max-height: 0;
	transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
	background-color: #6f8191;
	font-weight: 600;
	cursor: pointer;
	color: #fff !important;
	border-color: #6f8191;
	padding: 1rem 2.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
}
.accordion-title::after {
	content: '';
	width: 7px;
	height: 7px;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
	transform: rotate(-45deg);
	margin-top: 5px;
}
.accordion-title.open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.accordion-title.open + .accordion-item {
	overflow: visible !important;
}
.accordion-title.open::after {
	content: '';
	transform: rotate(135deg);
}
.accordion-content {
	padding: 1em 1.5em;
	background-color: #e7edf4;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
.accordion-number .spacing {
	width: 30px;
	display: inline-block;
}

.search-loader {
	width: 10px !important;
	height: 10px;
	display: block !important;
	float: right !important;
	top: 0 !important;
	position: absolute !important;
	bottom: 0 !important;
	right: 10px;
	margin: auto !important;
}

.modal-image-container {
	height: 120px;
}

.modal-image-container .modal-image {
	height: 100% !important;
}

/* .sub-name {
  padding: 10px 0
} */

.color-707070 {
	color: #707070;
}

.assignee-grid {
	padding: 2rem 0 !important;
	align-items: center !important;
}

.assignee-padding {
	padding: 0 1rem 0.5rem 1rem !important;
}

.message-content {
	margin-bottom: 2rem;
}
.service-checkbox .checkcontainer {
	padding: 4px 0px 0 28px;
}

.employee-list {
	min-height: 400px;
}
.employee-list-note {
	margin-bottom: 24px;
}
.file-error-message {
	background-color: #ffcbcb !important;
	border: none;
	color: #e55151 !important;
	box-shadow: none !important;
	font-size: 0.9em !important;
	font-weight: 600 !important;
	/* display: inline-block !important; */
}
.bulk-btn {
	width: 180px !important;
}
.bulk-btn:first-child {
	margin-right: 1rem;
}
.bulk-actions {
	margin: 48px 0 0 0;
}

.new-site-header-container {
	margin-bottom: 25px;
}

.new-site-title {
	margin-bottom: 15px !important;
}

.header-container {
	padding-bottom: 10px !important;
}

/* css reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	/* font-size: 100%; */
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'FS Elliot Pro', sans-serif !important;
}

input {
	font-family: 'FS Elliot Pro', sans-serif !important;
}
.ui.primary.button,
.ui.primary.buttons .button {
	background-color: #5badfa !important;
}
/* .field.service-radio > label > input:checked {
	background-color: red;
} */

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #707070 !important;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #707070 !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #707070 !important;
}

.ql-editor.ql-blank::before {
	color: #707070 !important;
	font-style: normal;
}

.setup-preview-tab-content::-webkit-scrollbar {
	width: 5px;
}

.setup-preview-tab-content::-webkit-scrollbar-track {
	border-radius: 10px;
}

.setup-preview-tab-content::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

/* .stepper-step-container .MuiPaper-root {
	padding: 24px 40px !important;
} */

.ui.search.dropdown > .text,
.ui.search.selection.dropdown > input.search {
	margin-left: 18px !important;
}

.ui.grid > .row {
	padding-top: 0.5em !important;
	padding-bottom: 0.5em !important;
}

.active.selected.item > .text {
	font-weight: initial !important;
}

.ui.input input {
	border: 0.25px solid #707070 !important;
	color: #707070;
}

input[type='text'],
textarea,
input[type='password'],
.ui.form .field > .selection.dropdown {
	border: 0.25px solid #707070 !important;
}

.ui.form .fields.ml-0 {
	margin-left: 0 !important;
}

.ui.form .fields.ml-0 {
	margin-right: 0 !important;
}

/* MATERIAL-UI Override CSS */
.MuiStepIcon-text {
	font-family: 'FS Elliot Pro', sans-serif !important;
}

.ui.dropdown .menu .active.item {
	font-weight: normal !important;
}

.ui.button.app-primary-inverted-button,
.ui.button.primary,
.ui.button.app-primary-button {
	font-size: 14px !important;
	padding: 17px auto !important;
	min-width: 170px;
	border: 1px solid #5badfa !important;
}
.ui.button.create-new-site {
	padding: 15.72px !important;
}
.login .google-button {
	padding: 17px 0 !important;
	border: 1px solid #4c7ae3;
}
.ui.selection,
.setup-preview-button {
	padding: 17px !important;
}
.field input {
	padding: 15.5px !important;
}
/* .ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
	padding: 18px !important;
} */
.ui.input input :not(.icon) {
	padding: 17px 36px !important;
	height: 48px !important;
}
.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
	top: 1em !important;
}
.ui.primary.button {
	border: 1px solid #5badfa !important;
}
.ui.button.app-primary-inverted-button:hover {
	background-color: transparent !important;
	color: #5badfa !important;
}

.ui.celled.table tr td,
.ui.celled.table tr th {
	padding-top: 18px !important;
	padding-bottom: 18px !important;
}

.ui.basic.blue.button,
.ui.basic.blue.buttons .button {
	box-shadow: none !important;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
	color: #707070;
}

