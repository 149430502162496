.ql-container {
	max-height: 501px;
	overflow-y: auto;
	font-size: 14px;
	color: #070707;
	font-family: 'FS Elliot Pro', sans-serif !important;
}

.overflow-none {
	overflow: hidden;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: attr(data-value) !important;
}
