.preview-container {
	background-color: #e9f0f7;
	padding: 38px 40px;
}

.preview-container h5,
.preview-container h5,
.preview-container h4,
.preview-container h3,
.preview-container h2,
.preview-container h1 {
	font-family: 'FS Elliot Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.preview-container-title {
	font-size: 16px;
	font-weight: bold;
}

.preview-container-text {
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 1.5rem;
}

.setup-preview {
	background-color: #f5faff;
	width: 100%;
	border-radius: 25px;
	box-shadow: 0px 3px 16px #00000024;
	overflow: hidden;
}

.setup-preview div,
.setup-preview p,
.setup-preview a {
	font-size: 10px;
	line-height: 14px;
}

.setup-preview .ui.segment {
	font-size: 10px;
}

.setup-preview-header {
	background-color: #6f8191;
	height: 60px;
}

.setup-preview-body {
	padding: 20px;
}

.setup-preview-title {
	font-size: 1.3em !important;
	font-weight: 700;
	margin-bottom: 0.5rem;
	word-wrap: break-word;
}

.setup-preview-text {
	word-wrap: break-word;
}

.service-preview-text-small {
	font-size: 8px;
}

.setup-preview-tab {
	margin-top: 25px;
}

.setup-preview-tab .tabular.menu {
	background-color: #cddae6;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	font-size: 0;
}

.setup-preview-tab .tabular.menu .item {
	width: 50%;
	color: #6f8191;
}

.setup-preview-tab .tabular.menu .item.active {
	border: none;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	position: relative;
}

.setup-preview-tab .tabular.menu .item:first-child.active::before {
	display: block;
	position: absolute;
	content: '';
	right: 0;
	top: 100% !important;
	transform: translate(100%, -100%);
	height: 10px;
	width: 10px;
	background-color: #fff;
	border-top: 1px solid #cddae6;
	border-right: 1px solid #cddae6;
}

.setup-preview-tab .tabular.menu .item:first-child.active::after {
	position: absolute;
	content: '';
	right: 0;
	bottom: 0;
	transform: translateX(100%);
	height: 10px;
	width: 10px;
	border-bottom-left-radius: 10px;
	background-color: #cddae6;
	z-index: 2;
}

.setup-preview-tab .tabular.menu .item:last-child.active::before {
	display: block;
	position: absolute;
	content: '';
	left: 0;
	top: 100% !important;
	transform: translate(-100%, -100%);
	height: 10px;
	width: 10px;
	background-color: #fff;
	border-top: 1px solid #cddae6;
	border-left: 1px solid #cddae6;
}

.setup-preview-tab .tabular.menu .item:last-child.active::after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	transform: translateX(-100%);
	height: 10px;
	width: 10px;
	border-bottom-right-radius: 10px;
	background-color: #cddae6;
	z-index: 2;
}

.setup-preview-tab .ui.segment {
	border: none !important;
	padding: 20px 15px;
	border-radius: 10pz;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	box-shadow: none !important;
}

.service-preview-item {
	margin-bottom: 12px;
}

.service-preview-item label {
	display: block;
	margin-bottom: 5px !important;
}

.setup-preview-button {
	background-color: #070707 !important;
	font-size: 10px !important;
	color: #fff !important;
	width: 100%;
	padding: 12px !important;
	font-weight: 400 !important;
	position: absolute;
	bottom: 20px;
	left: 0;
}

.setup-preview-tab-content {
	min-height: 400px;
	position: relative;
	width: 100%;
	/* word-wrap: break-word; */
	max-height: 400px;
	overflow-y: auto;
	word-break: break-all;
	overflow-x: hidden;
}

.service-preview-item-title {
	font-size: 1.5em !important;
	line-height: 20px;
	margin-bottom: 15px;
}

.service-preview-item .ui.selection.dropdown {
	padding: 11px !important;
	position: relative;
}

.service-preview-item .ui.selection.dropdown i {
	position: absolute;
	top: 75% !important;
	right: 11px;
	transform: translateY(-50%);
}

.service-preview-item input {
	padding: 13px !important;
}

.time-slots {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	row-gap: 10px;
}

.time-slots-item {
	background-color: #6f8191;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
}

.time-slots-item div {
	color: inherit;
	line-height: 1em;
}
.service-preview-item-content p {
	margin: 0 !important;
}
.service-preview-item-content ol,
.service-preview-item-content ul {
	padding-left: 2.5em !important;
}

.service-preview-item-content ol {
	list-style-type: decimal;
	list-style-position: inside;
}

.service-preview-item-content ul {
	list-style-type: disc;
	list-style-position: inside;
}

.service-preview-item-content h1 {
	font-size: 18px !important;
}
.service-preview-item-content h2 {
	font-size: 16px !important;
}
.service-preview-item-content h3 {
	font-size: 14px !important;
}
.service-preview-item-content h4 {
	font-size: 13px !important;
}
.service-preview-item-content h5 {
	font-size: 12px !important;
}
.service-preview-item-content h6 {
	font-size: 10px !important;
}
.service-preview-item-content .ql-align-center {
	text-align: center !important;
}
.service-preview-item-content .ql-align-right {
	text-align: right !important;
}
.service-preview-item-content .ql-align-justify {
	text-align: justify !important;
}
img.origin-icon {
	width: 30px !important;
	height: auto;
	z-index: 99999999;
	margin: 26.5px 8px;
	padding: 8px;
}

img.destination-icon {
	width: 30px !important;
	height: auto;
	z-index: 99999999;
	margin: 5px 8px 19px 8px;
	padding: 8px;
}

div.border-dotted {
	left: 22px;
	top: 49px;
	border-right: 2px dotted #6e7c9d;
	height: 50px !important;
	position: absolute;
	padding: 0;
}
