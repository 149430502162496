/* .field.service-radio > label > input:checked {
	background-color: red;
} */

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #707070 !important;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #707070 !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #707070 !important;
}

.ql-editor.ql-blank::before {
	color: #707070 !important;
	font-style: normal;
}

.setup-preview-tab-content::-webkit-scrollbar {
	width: 5px;
}

.setup-preview-tab-content::-webkit-scrollbar-track {
	border-radius: 10px;
}

.setup-preview-tab-content::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

/* .stepper-step-container .MuiPaper-root {
	padding: 24px 40px !important;
} */

.ui.search.dropdown > .text,
.ui.search.selection.dropdown > input.search {
	margin-left: 18px !important;
}

.ui.grid > .row {
	padding-top: 0.5em !important;
	padding-bottom: 0.5em !important;
}

.active.selected.item > .text {
	font-weight: initial !important;
}

.ui.input input {
	border: 0.25px solid #707070 !important;
	color: #707070;
}

input[type='text'],
textarea,
input[type='password'],
.ui.form .field > .selection.dropdown {
	border: 0.25px solid #707070 !important;
}

.ui.form .fields.ml-0 {
	margin-left: 0 !important;
}

.ui.form .fields.ml-0 {
	margin-right: 0 !important;
}

/* MATERIAL-UI Override CSS */
.MuiStepIcon-text {
	font-family: 'FS Elliot Pro', sans-serif !important;
}

.ui.dropdown .menu .active.item {
	font-weight: normal !important;
}

.ui.button.app-primary-inverted-button,
.ui.button.primary,
.ui.button.app-primary-button {
	font-size: 14px !important;
	padding: 17px auto !important;
	min-width: 170px;
	border: 1px solid #5badfa !important;
}
.ui.button.create-new-site {
	padding: 15.72px !important;
}
.login .google-button {
	padding: 17px 0 !important;
	border: 1px solid #4c7ae3;
}
.ui.selection,
.setup-preview-button {
	padding: 17px !important;
}
.field input {
	padding: 15.5px !important;
}
/* .ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
	padding: 18px !important;
} */
.ui.input input :not(.icon) {
	padding: 17px 36px !important;
	height: 48px !important;
}
.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
	top: 1em !important;
}
.ui.primary.button {
	border: 1px solid #5badfa !important;
}
.ui.button.app-primary-inverted-button:hover {
	background-color: transparent !important;
	color: #5badfa !important;
}

.ui.celled.table tr td,
.ui.celled.table tr th {
	padding-top: 18px !important;
	padding-bottom: 18px !important;
}

.ui.basic.blue.button,
.ui.basic.blue.buttons .button {
	box-shadow: none !important;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
	color: #707070;
}
