.employee-list {
	min-height: 400px;
}
.employee-list-note {
	margin-bottom: 24px;
}
.file-error-message {
	background-color: #ffcbcb !important;
	border: none;
	color: #e55151 !important;
	box-shadow: none !important;
	font-size: 0.9em !important;
	font-weight: 600 !important;
	/* display: inline-block !important; */
}
