.service-builder {
	line-height: 1.6em !important;
}

.service-builder-title {
	display: flex;
	justify-content: space-between;
}

.service-builder-title h3 {
	line-height: 1.1em !important;
}

.service-builder-edit {
	color: #5badfa !important;
	text-decoration: underline;
}

.service-builder-container {
	background-color: #fff;
	padding: 2rem;
	margin-bottom: 1rem;
}

.service-builder-inline {
	display: flex;
}

.service-builder-inline .service-builder-item:not(:last-child) {
	margin-right: 3rem;
}

.service-builder-item:not(:last-child) {
	margin-bottom: 2rem;
}

.service-builder-item .item-title {
	font-weight: 700;
}

.service-builder-item .service-icon {
	border: 1px solid #eee;
	border-radius: 5px;
	position: relative;
	width: 80px;
	height: 80px;
	margin-top: 5px;
}

.service-builder-item .service-icon-image {
	background-color: #eff4ff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
}

.service-builder-item .service-icon-image img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
}

.service-builder-item .item-attachment {
	background-color: #fafdff;
	border: 1px solid #c8d7e2;
	border-radius: 5px;
	padding: 1rem;
	margin-top: 5px;
	width: 100%;
	max-width: 400px;
	display: block;
	color: #222;
}

.service-builder ul {
	padding-left: 2rem;
	margin-top: 5px;
}

.service-builder ul li {
	list-style-type: disc;
}

.item-content {
	padding-top: 8px;
}

.ql-editor li::marker {
	color: transparent;
}

.service-builder .day-checkbox-item label {
	cursor: inherit;
}
