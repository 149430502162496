.service-dashboard-site-tab .ui.secondary.pointing.menu .item {
	width: 150px;
	justify-content: center;
	border-bottom-width: 4px;
	color: #6f8191;
}

.service-dashboard-site-tab .ui.secondary.pointing.menu .item.active {
	color: #559ff5 !important;
	border-color: #559ff5;
}

.service-dashboard-site-tab .tab {
	background-color: transparent;
	box-shadow: none;
	border: none;
}

.desks-modal {
	width: 100% !important;
	max-width: 500px !important;
	padding: 1.5rem;
}

.desks-modal .header {
	margin-bottom: 1.5rem;
}

.desks-modal .modal-action {
	margin-top: 2rem;
}

.desks-modal .modal-action .button:last-child {
	margin-bottom: 0;
}

.desks-modal .selection.dropdown {
	height: 47px;
	display: flex;
	align-items: center;
}

.bulk-completion-modal-icon,
.single-completion-modal-icon {
	text-align: center;
}

.bulk-completion-modal-icon img,
.single-completion-modal-icon img {
	width: 130px;
}

.report-card {
	background-color: #fff;
	padding: 2rem;
	border-radius: 10px;
	margin-bottom: 2rem;
}

.report-card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.report-card-header .header {
	margin-bottom: 0;
}

.report-card-subtitle {
	display: flex;
	align-items: center;
}

.report-card-subtitle-item {
	padding-left: 1rem;
	position: relative;
	font-size: 0.8rem;
}

.report-card-subtitle-item:not(:last-child) {
	margin-right: 1rem;
}

.report-card-subtitle-item::before {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: '';
	height: 10px;
	width: 10px;
	border-radius: 50%;
}

.report-card-subtitle-item.cancelled::before {
	background-color: #d1504b;
}

.report-card-subtitle-item.checkedin::before {
	background-color: #0056bf;
}

.report-card-action {
	display: flex;
	align-items: center;
}

.report-card-action .selection.dropdown {
	width: 200px;
}

.report-card-action label {
	white-space: nowrap;
	margin-right: 1rem;
}

.report-row {
	padding: 1rem;
	display: flex;
	justify-content: space-between;
}

.report-row:nth-child(odd) {
	background-color: #f2f5f8;
}

.report-row:nth-child(even) {
	background-color: #fafafa;
}

.report-row-item:last-child {
	font-weight: 700;
}

.status-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.status-text {
	color: #222;
}

.status-badge {
	height: 8px;
	width: 8px;
	margin-left: 0px;
	border-radius: 50%;
	margin-right: 1rem;
}

.status-free,
.status-active {
	background-color: #19cb5d;
	border: 1px solid #19cb5d;
}

.status-active {
	background-color: #38c5d1;
	border: 1px solid #38c5d1;
}

.status-inactive {
	background-color: #bdbdbd;
	border: 1px solid #bdbdbd;
}

.status-suspended,
.status-dismissed {
	background-color: #d13838;
	border: 1px solid #d13838;
}

.status-occupied {
	background-color: #cb2719;
	border: 1px solid #cb2719;
}

/* override filter input margin */
.slot-search-filter {
	display: flex !important;
	justify-content: space-between;
}

.slot-search-filter.ui.search.dropdown > .text,
.slot-search-filter.ui.search.selection.dropdown > input.search {
	margin-left: 0 !important;
	width: inherit;
	margin-right: 5px;
}
