@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Thin.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Thin.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Thin.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Thin.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Thin.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Thin.svg#FSElliotPro-Thin')
			format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Bold.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Bold.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Bold.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Bold.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Bold.svg#FSElliotPro-Bold')
			format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-BoldItalic.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-BoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-BoldItalic.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-BoldItalic.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-BoldItalic.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-BoldItalic.svg#FSElliotPro-BoldItalic')
			format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro.woff2') format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro.woff') format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro.ttf') format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro.svg#FSElliotPro')
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-HeavyItalic.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-HeavyItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-HeavyItalic.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-HeavyItalic.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-HeavyItalic.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-HeavyItalic.svg#FSElliotPro-HeavyItalic')
			format('svg');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Light.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Light.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Light.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Light.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Light.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Light.svg#FSElliotPro-Light')
			format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-LightItalic.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-LightItalic.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-LightItalic.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-LightItalic.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-LightItalic.svg#FSElliotPro-LightItalic')
			format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-ThinItalic.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-ThinItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-ThinItalic.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-ThinItalic.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-ThinItalic.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-ThinItalic.svg#FSElliotPro-ThinItalic')
			format('svg');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Heavy.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Heavy.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Heavy.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Heavy.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Heavy.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Heavy.svg#FSElliotPro-Heavy')
			format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FS Elliot Pro';
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Italic.eot');
	src: url('../assets/fonts/fs-elliot-pro/FSElliotPro-Italic.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Italic.woff2')
			format('woff2'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Italic.woff')
			format('woff'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Italic.ttf')
			format('truetype'),
		url('../assets/fonts/fs-elliot-pro/FSElliotPro-Italic.svg#FSElliotPro-Italic')
			format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

.ui.header {
	font-family: 'FS Elliot Pro', sans-serif;
}

.grey-text {
	color: #6f8191 !important;
}

a {
	color: #5badfa;
}

.ui.button {
	padding: 1.1rem;
	border-radius: 10px !important;
	font-weight: normal;
	font-family: 'FS Elliot Pro', sans-serif;
}

.ui.button.app-primary-button {
	background-color: #5badfa;
	color: #fff;
}

.ui.button.prev,
.ui.button.next {
	min-width: 145px;
}

.ui.button.next {
	align-self: flex-end;
}

.ui.button.app-primary-button:hover {
	background-color: #5badfa !important;
}

.ui.button.app-primary-inverted-button {
	background-color: transparent;
	border: 1px solid #5badfa;
	color: #5badfa;
}

.ui.button.app-primary-inverted-button:hover {
	border-color: #077be8;
}

.ui.button.full-button {
	width: 100%;
	padding: 1.1rem;
	margin-bottom: 1rem;
}

.ui.button.medium-button {
	padding: 1.1rem 4rem;
}

.ui input {
	padding: 1rem 1.5rem !important;
	border-radius: 10px !important;
	font-family: FS Elliot Pro !important;
}

.ui input:focus {
	background-color: #f2f8fd !important;
}

.ui input.input-error {
	border: 1px solid #eeafaf !important;
	background-color: #fffafa !important;
	/* color: #BB5656 !important; */
}

.ui.input {
	/* width: 100% !important; */
}

.ui.label {
	display: flex !important;
	align-items: center !important;
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}

.input-error-message {
	color: #db2828;
	display: block;
	text-align: right;
}

.field {
	width: 100% !important;
}

.ui.selection.dropdown {
	min-width: auto;
	max-height: 49px !important;
}

.grouped.fields {
	margin-bottom: 20px !important;
}

.grouped.fields > label {
	font-size: 1em !important;
}

.stepper-step-container .MuiPaper-root,
.stepper-step-container .MuiTypography-root + div {
	width: 62.5%;
}

.MuiTypography-root + div {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}

.MuiButton-containedPrimary {
	padding: 1.1rem 4rem !important;
	background-color: #5badfa !important;
	box-shadow: none !important;
	border-radius: 10px !important;
}

.MuiButton-containedPrimary:hover {
	background-color: #077be8 !important;
}

.makeStyles-backButton-2 {
	padding: 1.1rem 4rem !important;
	border: 1px solid #5badfa !important;
	background-color: transparent !important;
	box-shadow: none !important;
	border-radius: 10px !important;
	color: #5badfa !important;
}

.makeStyles-backButton-2:hover {
	background-color: #5badfa !important;
	color: #fff !important;
}

.makeStyles-backButton-2.Mui-disabled {
	border: 1px solid gray !important;
	color: gray !important;
}

.stepper-step-container .MuiPaper-root {
	background-color: transparent !important;
	margin-bottom: 2rem;
	display: flex;
	align-items: center !important;
}

.MuiPaper-root .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
	height: 46px;
	width: 46px;
	position: relative;
}

.MuiPaper-root .MuiStepIcon-root {
	height: 46px;
	width: 46px;
	border-radius: 50%;
	background-color: #fff !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.MuiPaper-root .MuiStepIcon-root circle {
	fill: #6f8191;
	r: 9.5;
}

.MuiPaper-root .MuiStepIcon-root text {
	font-size: 0.6rem;
	y: 14 !important;
	transform: translateY(0.7px);
}

.MuiPaper-root .MuiStepIcon-root.MuiStepIcon-active {
	height: 46px;
	width: 46px;
	background-color: #fff;
	border: 1px solid #0064d8;
	position: relative;
}

.MuiPickersModal-dialogRoot {
	width: inherit !important;
	background-color: white !important;
}

.MuiPaper-root .MuiStepIcon-root.MuiStepIcon-active circle {
	fill: #0064d8 !important;
	r: 9.5;
}

.MuiPaper-root .MuiStepIcon-root.MuiStepIcon-completed {
	height: 37px;
	width: 37px;
	color: #0064d8;
}

.MuiStepper-alternativeLabel > .MuiStep-alternativeLabel {
	flex-grow: 0 !important;
	flex-shrink: 0 !important;
	flex-basis: auto !important;
	padding: 0;
}

.MuiStepper-alternativeLabel > .MuiStep-alternativeLabel:not(:first-child) {
	width: 20% !important;
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel:not(:first-child)::after {
	content: '';
	position: absolute;
	top: 52%;
	right: 5px;
	width: 100%;
	transform: translateY(-50%);
	height: 1px;
	background-color: #6f8191;
	z-index: -1;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel
	.MuiStepConnector-alternativeLabel {
	display: none;
}

.MuiStepper-alternativeLabel > .MuiStep-alternativeLabel .MuiStepLabel-root {
	position: relative;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel
	.MuiStepLabel-root
	.MuiStepLabel-labelContainer {
	position: absolute;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
}

.MuiStepper-alternativeLabel
	> .MuiStep-alternativeLabel
	.MuiStepLabel-root
	.MuiStepLabel-labelContainer
	.MuiStepLabel-label {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
	background-color: #5badfa !important;
}

.MuiPickersClockPointer-thumb {
	border-color: #5badfa !important;
}

.MuiButton-label {
	color: #5badfa !important;
}

.step-container {
	background-color: #fff;
	border-radius: 10px;
	padding: 2rem;
}

.step-highlight {
	padding: 2rem;
	background-color: #fafafa;
}

* {
	font-family: 'FS Elliot Pro', sans-serif;
}

.element-container {
	padding: 2rem;
	height: 100%;
	margin-bottom: 2rem;
}

.element-container-white {
	background-color: #fff;
}

.element-container-gray {
	background-color: #e9f0f7;
}

.element-header {
	border-bottom: 1px solid gainsboro;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.table-list {
	/* overflow-x: auto; */
}

.table-container {
	overflow-x: auto;
	margin-bottom: 2em;
}

.table-list-filter {
	margin-bottom: 1rem;
	width: 100%;
}

.table-list-filter label {
	white-space: nowrap;
}

.table-list-filter .field {
	width: auto !important;
}

.table-list-filter .ui.selection.dropdown {
	min-width: 14em;
}

.table-list-actions {
	margin-left: auto;
}

.table-list tr th,
.table-list tr td {
	padding: 1rem 2rem !important;
}

.table-list tr th {
	background-color: #3d4e5d !important;
	color: #fff !important;
	text-align: left;
}

.table-list tbody tr:nth-child(odd) td {
	background-color: #fff !important;
}

.table-list tbody tr:nth-child(even) td {
	background-color: #edf3f8 !important;
}

.table-list .field {
	padding-right: 5px !important;
}

.table-list .field .field {
	padding-right: 0 !important;
}

/* .table-list .field .selection.dropdown {
  min-width: 150px;
  width: 150px !important;
} */

/* .table-list .ui.button {
	width: 150px;
	font-weight: 400;
} */
.actions .button:not(:last-child) {
	margin-right: 1rem;
}

.ui.breadcrumb .section {
	color: #6e7c9d;
}

.ui.breadcrumb .active.section {
	color: #222 !important;
}

.page-title {
	margin-bottom: 0 !important;
}

.page-header {
	margin-bottom: 3rem;
	margin-top: 0.14285714em !important;
	font-weight: normal !important;
}

.page-header .page-subtitle {
	font-size: 16px;
}

.character-count {
	color: #6f8191;
	font-size: 0.9rem;
	text-align: right;
}

.character-count-input {
	margin-bottom: 0 !important;
}

.quill .ql-toolbar.ql-snow {
	background-color: #3d4e5d;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.quill .ql-toolbar.ql-snow svg * {
	stroke: #fff !important;
}

.quill .ql-toolbar.ql-snow svg *:hover {
	stroke: #fff;
}

.quill .ql-toolbar.ql-snow .ql-picker-label {
	color: #fff;
}

.quill .ql-toolbar.ql-snow .ql-picker-label:hover {
	color: #fff !important;
}

.quill .ql-container {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.quill .ql-editor {
	min-height: 500px;
	border-bottom: 1px solid #707070 !important;
	border-left: 1px solid #707070 !important;
	border-right: 1px solid #707070 !important;
}

.app-modal.ui.modal {
	padding: 2rem 0;
}

.app-modal.ui.modal .content {
	text-align: center !important;
	padding: 1.5rem 3rem;
}

.app-modal.ui.modal .content img {
	width: 100px;
}

.app-modal.ui.modal .app-primary-button {
	margin-top: 1rem;
}

.vertical-center {
	display: flex !important;
	align-items: center !important;
}

.icon.error {
	color: #db2828 !important;
}

.page-guidelines {
	padding: 2rem 1.5rem;
	background-color: #e9f0f7;
	border-radius: 10px;
}

.page-guidelines .ui.header {
	display: flex;
	align-items: center;
	margin-bottom: 2rem !important;
}

.page-guidelines .ui.header.subtitle {
	margin-bottom: 0.5rem !important;
}

.page-guidelines i.icon {
	font-size: 18px !important;
	position: relative;
}

.page-guidelines i.icon::before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.page-guidelines .bulleted.list .item {
	margin-bottom: 1rem;
}

.page-guidelines .bulleted.list .item::before {
	color: #b3bbc4;
}

.page-guidelines .bulleted.list .item a {
	text-decoration: underline;
}

.page-guidelines .preview {
	width: 100%;
	border-radius: 25px;
}

.overlay {
	z-index: 9999 !important;
	position: fixed !important;
}

.color-black {
	color: black !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

.workflow {
	margin-top: 1rem;
}

.workflow-title {
	font-weight: 700;
}

.content-container {
	padding: 2rem;
	background-color: #fff;
}

.content-container .form {
	width: 100%;
}

.ui.form .field > label {
	/* color: rgba(0,0,0,.87); */
	font-size: 1em !important;
	font-weight: 500 !important;
}

/* .service-radio label {
	text-align: center;
	vertical-align: center;
	align-items: center;
	display: flex !important;
}

.service-radio input[type='checkbox'],
.service-radio input[type='radio'] {
	width: 1.25em !important;
	height: 1.25em !important;
	margin: 0 8px;
} */

.input-check-container {
	margin: 8px 5px 8px 0 !important;
}

.checkcontainer {
	display: revert !important;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	font-size: 1em;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkcontainer input[type='radio'],
.checkcontainer input[type='checkbox'] {
	position: absolute;
	opacity: 0 !important;
	cursor: pointer;
}
[type='checkbox'],
[type='radio'] {
	padding: 0;
}

/* Create a custom checkbox */
.radiobtn,
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #707070;
}

.checkmark {
	border-radius: 3px !important;
}

.radiobtn {
	border-radius: 50%;
}

/* checked styles of radio and checkbox */
.checkcontainer input:checked ~ .radiobtn {
	background-color: white;
	border: 1px solid #559ff5;
}

.checkcontainer input:checked ~ .checkmark {
	background-color: #559ff5;
	border: none;
}

.checkcontainer input:checked ~ .disabled-checked {
	background-color: #559ff5 !important;
	border: none !important;
	opacity: 0.45 !important;
}

.checkcontainer input[type='radio']:disabled,
.checkcontainer input[type='checkbox']:disabled {
	cursor: default !important;
}

/* disable state of checkbox */
.checkcontainer input:disabled ~ .checkmark,
.checkcontainer input:disabled ~ .radiobtn {
	background-color: #f0f0f0;
	border: none !important;
	cursor: default;
}

.checkcontainer input:disabled ~ span,
.checkcontainer input:disabled ~ span {
	cursor: default !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radiobtn:after,
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked ~ .radiobtn:after,
.checkcontainer input:checked ~ .checkmark:after {
	display: block;
}

.checkcontainer input[type='checkbox'] + span {
	color: #707070;
}

.booking-checkbox.checkcontainer input[type='checkbox'] + span {
	color: #000000;
}

.booking-checkbox.checkcontainer input[type='checkbox']:checked + span {
	font-weight: normal !important;
	color: #000000 !important;
}

/* disabled checkbox font style */
.booking-checkbox.checkcontainer input[type='checkbox']:disabled + span {
	color: #707070 !important;
}

.checkcontainer input[type='checkbox']:checked + span {
	font-weight: bold;
	color: #222222;
}

/* Style the checkmark/indicator */
.checkcontainer .radiobtn:after {
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #5badfa;
}

.checkcontainer .checkmark:after {
	left: 8px;
	top: 4px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.input-pointer {
	margin: 0;
	margin-left: 10px !important;
}

.input-pointer .input > input[type='text'] {
	cursor: pointer;
}

.MuiDialogActions-root {
	width: 100% !important;
}

.ui.basic.button.btn-header,
.ui.basic.button.btn-header:hover,
.ui.basic.button.btn-header:focus {
	box-shadow: none !important;
	padding: 0;
	font-weight: 700;
}

.guidelines-txt {
	margin: 10px 0 0 10px;
}

.idea-img {
	width: auto !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-default {
	cursor: default !important;
}

.setup-welcome .app-primary-button {
	margin-top: 1rem;
}

div.google-visualization-tooltip > ul > li > span {
	color: white !important;
}

div.google-visualization-tooltip {
	background: #6f8191 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
	opacity: 1;
}

svg > g > g:last-child {
	pointer-events: none;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	background-color: #3d4e5d !important;
}

.chart-tooltip {
	color: white !important;
	padding: 16px !important;
}

.chart-tooltip > div > span {
	white-space: nowrap !important;
}

.text-center {
	text-align: center !important;
}

.ui.table tr td:last-child {
	white-space: nowrap;
}

.input-class.filter-searchContainer {
	align-items: center !important;
	max-width: 300px !important;
}

.filter-searchContainer input {
	padding-top: 18px !important;
	padding-bottom: 18px !important;
	font-weight: normal !important;
}

.filter-searchIcon {
	position: absolute;
	width: 38px;
	height: 38px;
	padding: 10px;
	top: 0;
	left: 2px;
	bottom: 0;
	margin: auto;
}

.custom-full-button {
	width: 100% !important;
	margin: 0.5rem 0 !important;
}

.custom-half-button {
	width: 50% !important;
	margin: 0.5rem 0 !important;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
	.ui.container {
		width: 100%;
	}

	.ui.container.page-container {
		padding-bottom: 24px !important;
	}

	.ui.grid.container {
		width: calc(100% + 2rem) !important;
	}

	.ui.relaxed.grid.container {
		width: calc(100% + 3rem) !important;
	}

	.ui.very.relaxed.grid.container {
		width: calc(100% + 5rem) !important;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1608px) {
	.ui.button.app-primary-inverted-button,
	.ui.button.primary,
	.ui.button.app-primary-button {
		min-width: 130px !important;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1800px) {
	.ui.container {
		width: 90%;
	}

	.ui.container.page-container {
		padding-bottom: 24px !important;
	}

	.ui.grid.container {
		width: calc(90% + 2rem) !important;
	}

	.ui.relaxed.grid.container {
		width: calc(90% + 3rem) !important;
	}

	.ui.very.relaxed.grid.container {
		width: calc(90% + 5rem) !important;
	}
}

@media only screen and (min-width: 1800px) {
	.ui.container {
		width: 80%;
	}

	.ui.container.page-container {
		padding-bottom: 24px !important;
	}

	.ui.grid.container {
		width: calc(80% + 2rem) !important;
	}

	.ui.relaxed.grid.container {
		width: calc(80% + 3rem) !important;
	}

	.ui.very.relaxed.grid.container {
		width: calc(80% + 5rem) !important;
	}
	.preview-container {
		padding: 38px 60px !important;
	}
}

.ui.button.app-primary-button:hover {
	background-color: #5badfa !important;
}

.w-100 {
	width: 100% !important;
}

.btnLink {
	cursor: pointer;
	margin: 3px;
	text-decoration: underline;
	color: #5badfa !important;
}

td > .btnLink:first-child {
	margin-left: 0 !important;
}

.btnLink:hover {
	text-decoration: underline;
	color: #5badfa !important;
}

td > .btnLink:first-child {
	margin-left: 0 !important;
}

.filter-select i.icon {
	float: right;
}
i.angle.down.icon {
	margin-right: -5px;
	/* padding-left: 10px; */
}

.ui.search.dropdown > input.search {
	padding-right: 35px !important;
}
.form-grid-row {
	align-items: flex-end !important;
	padding: 0 0 1em !important;
}
.error-message {
	background-color: #ffcbcb !important;
	color: #e55151 !important;
	border: none !important;
	box-shadow: none !important;
	padding-top: 18px !important;
	padding-bottom: 18px !important;
	display: inline-flex;
	align-items: center;
	margin: 5px 0 !important;
}
.error-message img {
	margin-right: 8px;
}
.error-message span {
	font-size: 12px;
	font-weight: 600;
}
.MuiStepIcon-root.MuiStepIcon-completed {
	background-image: radial-gradient(
		white 30%,
		#0064d8 15%,
		#0064d8 60%
	) !important;
	padding: 6px !important;
}

.img-success-message {
	margin-top: 0 !important;
}

.img-success-message > span {
	margin-right: 12px !important;
}

.ungroup-fields.grouped.fields {
	margin-bottom: 0px !important;
}

.margin-subfields {
	margin: -1.25rem 1rem !important;
}

.subfields-margin {
	margin-top: -1em !important;
}
.w-100 {
	width: 100% !important;
}

.w-90 {
	width: 90% !important;
}

.w-95 {
	width: 95% !important;
}

.w-80 {
	width: 80% !important;
}

.w-75 {
	width: 75% !important;
}
.w-50 {
	width: 50% !important;
}

.w-10 {
	width: 10% !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.mh-100 {
	max-height: 100% !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
	margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
	margin-left: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1,
.py-1 {
	padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
	padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pr-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
	padding-left: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

.d-none {
	display: none !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

.fw-100 {
	font-weight: 100;
}

.fw-200 {
	font-weight: 200;
}

.fw-300 {
	font-weight: 300;
}

.fw-400 {
	font-weight: 400;
}

.fw-bold {
	font-weight: bold;
}

.time-picker .ui.icon.input > i.icon {
	opacity: 1 !important;
	color: #707070 !important;
}

.btn-link {
	cursor: pointer;
}

.underline {
	text-decoration: underline;
}
.company-details-font {
	color: #6f8191 !important;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}

.MuiPaper-root .MuiStepIcon-root text {
	dominant-baseline: text-after-edge;
	font-size: 0.5rem;
}

.color-subheader {
	color: #919191 !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.ui.page.modals.dimmer.transition.visible.active {
	z-index: 2100;
}

.ui.selection.dropdown .menu>.item {
    word-break: break-all !important;
}

.ui.search.dropdown > .text, .ui.search.selection.dropdown > input.search {
	overflow: hidden;
}

.ui.selection.dropdown {
	word-wrap: unset !important;
}