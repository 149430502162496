.sub-title-shuttle-date {
	/* margin: 2rem 0px 2rem 0px; */
	margin-top: 1.8rem;
	margin-bottom: 1.5rem;
	font-weight: 600;
	font-size: 16px;
}

.custom-no-available-trips {
	/* font-weight: 600; */
	font-size: 16px;
}

.mb-1rem {
	margin-bottom: 1.5rem;
}

.space-no-wrap {
	white-space: nowrap;
}

.edit-trip-modal-content {
	background-color: #f2f7fc;
	/* text-align: center; */
	padding: 10px;
	margin-bottom: 1rem;
}

.disable-anchor-tag {
	pointer-events: none;
	/* text-decoration: underline; */
	color: #989696 !important;
}
